<template>
  <div class="help-btn">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on" fab @click="clickHelp"><v-icon large>mdi-face-agent</v-icon></v-btn>
      </template>
      <span>Проблемы? Напишите нам.</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "HelpButton",

  methods: {
    clickHelp() {
      const text = `Добрый день, прошу помочь. Столкнулся с проблемой на сайте: (постарайтесь максимально описать проблему, чтобы мы быстрее ее устранили)`;
      //наверно как-то лучше разделить каналы по заявкам и проблемы на сайте
      // const url = `whatsapp://send?phone=79009210281&text=${encodeURIComponent(text)}`;
      const url = `https://wa.me/79009210281?text=${encodeURIComponent(text)}`;

      window.open(url, "_blank").focus();
      // window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.help-btn {
  z-index: 9001;
  position: fixed;
  top: 4rem;
  right: 3px;
}
</style>