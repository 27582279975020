import { $host } from '../../libs/user-api';
export default {
  state: {
    allFilials: [],
    currentFilial: {
      deliveryAtNight: '',
      deliveryAvalible: '',
      deliveryNonWorkHours: [],
      deliveryPlaces: [],
      deliveryAgreement: '',
      deliveryTerms: [],
      deniedRepublics: [],
      mayTripOutsideRf: '',
      deniedTerritory: '',
      schedule: [],
      shownServices: [],
      paymentLink: '',
      filialId: null,
      minutesBetweenBooking: 0,
      minutesFromNow: 60,
      timezone: null,
      bookingPeriod: 0
    },
    tarifs: []
  },
  mutations: {
    updateFilials(state, filials) {
      state.allFilials = filials;
    },
    clearCurrentFilial(state) {
      state.currentFilial.deliveryAtNight = '';
      state.currentFilial.deliveryAvalible = '';
      state.currentFilial.deliveryNonWorkHours = [];
      state.currentFilial.deliveryPlaces = [];
      state.currentFilial.deliveryAgreement = '';
      state.currentFilial.deliveryTerms = [];
      state.currentFilial.deniedRepublics = [];
      state.currentFilial.mayTripOutsideRf = '';
      state.currentFilial.deniedTerritory = '';
      state.currentFilial.schedule = [];
      state.currentFilial.shownServices = [];
      state.currentFilial.filialTarifsAuto = [];
      state.currentFilial.filialTarifsHut = [];
      state.currentFilial.paymentLink = '';
      state.currentFilial.filialId = null;
      state.currentFilial.minutesBetweenBooking = 0;
      state.currentFilial.minutesFromNow = 60;
      state.currentFilial.timezone = null;
      state.currentFilial.bookingPeriod = 0;
    },
    updateCurrentFilial(state, filial) {
      state.currentFilial = filial;
    },
    updateTarifs(state, array) {
      state.tarifs = array;
    },
    // updateCurrentFilialExact(state, {key, value}) {
    //   state.currentFilial[key] = value
    // }
  },
  actions: {
    async getAllFilials(ctx) {
      try {
        const { data } = await $host.get(`/freecars/branches`);
        // console.log('this is data:::', data)
        ctx.commit('updateFilials', data.allFilials);
        ctx.commit('updateTarifs', data.tarifs);
      } catch (e) {
        console.log('error with get all filials');
      }
    },
    async getFilalByCarId(ctx, carId) {
      const isNan = Number.isNaN(parseInt(carId));
      if (!isNan) {
        try {
          const carDetails = await $host.get('/freecars/branches/car', {
            params: {
              carId: carId,
            },
          });
          const filial = {};
          for (let key in carDetails.data) {
            filial[key] = carDetails.data[key];
          }
          ctx.commit('updateCurrentFilial', filial);
          return filial;
        } catch (e) {
          return null;
        }
      } else return null;
    },
    async findFilialById(ctx, filialId) {
      // filialId хранится у машины в сторе
      if (!filialId || this.state.filials.allFilials.length === 0) return null;
      const filial = this.state.filials.allFilials.find(
        (x) => x.filialId == filialId
      );
      if (!filial) return null;
      ctx.commit('updateCurrentFilial', filial);
      return filial
    },
  },
  getters: {
    allFilials: (state) => {
      return state.allFilials;
    },
    currentFilial: (state) => {
      return state.currentFilial;
    },
  },
};
