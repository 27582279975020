<template>
  <v-card class="pa-5">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <v-card-title>
      Создать новое обращение
    </v-card-title>
    <v-textarea v-model="text">
      <template v-slot:label>
        <div>
          Текст обращения
        </div>
      </template>
    </v-textarea>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="send"
        :disabled="loading || showPopUp"
      >
        Отправить
      </v-btn>
    </v-card-actions>
    <v-alert
      transition="scale-transition"
      class="ma-1"
      dense
      outlined
      :type="typeStyle"
      :value="showPopUp"
      >{{ messagePopUp }}</v-alert
    >
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Appeal',
  props: {
    goBack: {
      type: Function,
      default: function() {
        console.log('default');
      },
    },
  },
  data() {
    return {
      text: '',
      loading: false,
      messagePopUp: '',
      showPopUp: false,
      typeStyle: 'warning',
    };
  },
  methods: {
    async send() {
      this.loading = true;
      const data = await this.createAppeal(this.text);
      this.messagePopUp = data.message;
      this.typeStyle = data.typeStyle;
      this.loading = false;
      this.flashPopUp();
    },
    flashPopUp() {
      this.showPopUp = true;
      setTimeout(() => {
        this.showPopUp = false;
        this.messagePopUp = '';
        this.text = '';
        this.goBack();
      }, 3000);
    },
    ...mapActions(['createAppeal']),
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
