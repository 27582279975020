import Vue from 'vue';
import Vuex from 'vuex';
import cars from './modules/cars';
import cities from './modules/cities';
import user from './modules/user';
import operations from './modules/operations';
import booking from './modules/booking';
import filials from './modules/filials';
import global from './modules/global';
import apartments from './modules/apartments/apartments';
//import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: 'a-prokat',
  },
  mutations: {},
  actions: {},
  //  plugins: [createPersistedState()],
  modules: {
    global,
    user,
    cars,
    cities,
    apartments,
    operations,
    booking,
    filials,
  },
  getters: {},
});
