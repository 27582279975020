import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru'
import VueMeta from 'vue-meta'


Vue.use(Vuetify);

Vue.use(VueMeta, {
// optional pluginOptions
refreshOnceOnNavigation: true
})

export default new Vuetify({
    theme: { dark: true },
    lang: {
        locales: { ru },
        current: 'ru'
    },
    icons: {
        iconfont: 'mdi'
    }
});
