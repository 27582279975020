import Vue from "vue";
import VueRouter from "vue-router";

// import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: "/docload",
    name: "docload",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../components/DocLoad")
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../views/Login")
  },
  {
    path: "/set_password",
    name: "set password",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../views/Login")
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/Profile")
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/Calendar")
  },
  {
    path: "/cars/:city",
    name: "cars",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/Cars")
  },
  // {
  //   path: '/apartments/:city',
  //   name: 'apartments',
  //   meta: {
  //     auth: false,
  //     layout: 'navbar',
  //   },
  //   component: () => import('../views/apartments/Apartments'),
  // },
  // {
  //   path: '/apartment/:apartmentId',
  //   name: 'apartment',
  //   meta: {
  //     auth: false,
  //     layout: 'navbar',
  //   },
  //   component: () => import('../views/apartments/Apartment'),
  // },
  {
    path: "/",
    name: "main",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/MainPlaceHolder")
  },
  {
    path: "/auth/booking",
    name: "carslogin",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../views/Cars")
  },
  {
    path: "/car/:carRoute",
    name: "car",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/Car")
  },
  {
    path: "/messages",
    name: "messages",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/Messages")
  },
  {
    path: "/operations/invest",
    name: "investOperations",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/Operations")
  },
  {
    path: "/operations/my",
    name: "myOperations",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/OperationsMy")
  },
  {
    path: "/myCars/inspections",
    name: "carOperations",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/OperationsCar")
  },
  {
    path: "/myCars/carSettings",
    name: "carSettings",
    meta: {
      auth: true,
      layout: "navbar"
    },
    component: () => import("../views/carSettings")
  },
  {
    path: "/cars",
    name: "carsOld",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/MainPlaceHolder")
  },
  {
    path: "/citys",
    name: "citysOld",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/MainPlaceHolder")
  },
  // {
  //   path: '/apartments',
  //   name: 'apartmentsOld',
  //   meta: {
  //     auth: false,
  //     layout: 'navbar',
  //   },
  //   component: () => import('../views/MainPlaceHolder'),
  // },
  {
    path: "/logout",
    name: "logoutPlaceholder",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/MainPlaceHolder")
  },
  {
    path: "/pay/:bookingId",
    name: "timeToPayIsExpired",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../views/TimeExpired")
  },
  {
    path: "/referals",
    name: "referals",
    meta: {
      auth: false,
      layout: "navbar"
    },
    component: () => import("../views/Referals")
  },
  {
    path: "/vidjet",
    name: "vidjet",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../components/vidjet/vidjet")
  },
  {
    path: "*",
    name: "404",
    meta: {
      auth: false,
      layout: "empty"
    },
    component: () => import("../views/404")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //to, from, savedPosition
    return { x: 0, y: 0 };
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.path == '/logout') {
//     store.dispatch('logoutUser');
//     // to.path = '/cars';
//   }
//   const user = store.getters.userAuth;
//   // || localStorage.isAuthAprokat === 'true';
//   const requireAuth = to.matched.some((record) => record.meta.auth);
//   if (requireAuth && !user) {
//     // next('/login?message=login');
//   } else {
//     next();
//   }
// });

export default router;
