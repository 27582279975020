<template>
  <v-app class="gray lighten-4">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showAuthForm" max-width="600" persistent>
      <AuthForm />
    </v-dialog>
    <component :is="layout" />
    <v-main class="mx-4 mb-4">
      <HelpButton />
      <router-view></router-view>
    </v-main>
    <!-- <Footer /> -->
    <script type="application/javascript" src="//tagmanager.andata.ru/api/v1/container/9b7ba201-f90b-4c3f-a8b4-fbcf8124cf94/published/code.js">
    </script>
  </v-app>
</template>
<script>
import Navbar from "@/layouts/Navbar";
import Empty from "@/layouts/Empty";
// import Footer from '@/layouts/Footer';
import HelpButton from "./components/HelpButton.vue";
import AuthForm from "./components/AuthForm.vue";
// import SocketIO from 'socket.io-client';

import { mapActions, mapGetters, mapMutations } from "vuex";
import { find } from "lodash";

export default {
  metaInfo() {
    return {
      title: "А-прокат"
    };
  },
  name: "App",
  components: {
    Navbar,
    Empty,
    HelpButton,
    AuthForm
    // Footer,
  },
  data: () => ({
    loading: false
  }),
  computed: {
    layout() {
      // console.log('this.bookingState.cityLink ', this.bookingState.cityLink);
      this.checkAndUpdateCookie()
      this.checkAndGetUTM(this.$route.query);
      if (this.bookingState.routeLink && !this.$route.name == "referals") {
        //this.$router.push(this.bookingState.routeLink);
      }
      return this.$route.meta.layout || "Empty";
    },
    ...mapGetters([
      "allCities",
      "bookingState",
      "user",
      "bookingApartment",
      "socket",
      "showAuthForm"
    ])
  },
  // sockets: {
  //   olo: function(data) {
  //     console.log('data from appp', data);
  //   },
  // },
  methods: {
    ...mapMutations(["resetBookingState"]),
    async pause(timer = 500) {
      return new Promise(function (resolve) {
        setTimeout(() => {
          resolve();
        }, timer);
      });
    },
    ...mapActions([
      "getUser",
      "getBooking",
      "getUTM",
      "getAllFilials",
      "getCitites",
      // 'getInfoAboutApartmentsBookingByUser',
      // 'getDenyReasons',
      "refreshDocsSocket",
      "checkAndGetUTM",
      "checkAndUpdateCookie",
      // 'getGlobalTest',
    ])
  },
  async created() {
    this.loading = true;
    // await this.getGlobalTest();
    console.time("load");
    await Promise.allSettled([
      this.getUser(),
      this.getAllFilials(),
      this.getCitites()
    ]);
    // await this.getUser();
    // await this.getDenyReasons()
    if (
      window.origin == "https://lk.a-prokat.com/" ||
      window.origin.includes("//lk.")
    ) {
      this.loading = false;
      if (this.user.auth) this.$router.push("/operations/my");
      else this.$router.push("/login");
    }

    // await this.getAllFilials();
    // await this.getCitites();
    console.timeEnd("load");

    if (this.$route.name == "timeToPayIsExpired") {
      await this.pause(5000);
    }
    const dataCar = await this.getBooking();
    // const dataApartment = await this.getInfoAboutApartmentsBookingByUser();
    const dataApartment = null;

    // console.log('this is bookubg id::', this.bookingState.currentBookingId);
    // this.$socket.connection.handshake['ololo'] = 'ololo';
    // console.log('this socket:::', this.$socket.connection.handshake);
    // this.$socket.on(`update:booking`, (data) => {
    //   console.log('data from socket::', data);
    //   this.refreshDocsSocket(data);
    // });

    this.loading = false;
    if (
      (dataCar &&
        dataCar.cityId &&
        !dataApartment &&
        this.$route.name != "apartments") ||
      (this.bookingState &&
        this.bookingState.cityId &&
        this.$route.name != "apartments")
    ) {
      const cityToPush = find(this.allCities, o => {
        return o.id === this.bookingState.cityId;
      });
      this.loading = false;
      if (
        cityToPush &&
        this.$route.name != "car" &&
        this.$route.name != "vidjet"
      )
        this.$router.push({
          name: "cars",
          params: { city: cityToPush.nameTranslite }
        });
    } else {
      if (
        dataApartment &&
        dataApartment.cityId &&
        !dataCar.carId &&
        this.$route.name != "cars"
      ) {
        const cityToPush = find(this.allCities, o => {
          return o.id === this.bookingApartment.cityId;
        });
        this.loading = false;
        if (cityToPush)
          this.$router.push({
            name: "apartments",
            params: { city: cityToPush.nameTranslite }
          });
      }
    }
    if (this.socket) {
      console.log("soket!");
      // console.log('connect by id', this.user.id)
      // const socket = SocketIO('https://testapp.a-prokat.com/', {
      //   query: { userId: this.user.id },
      // });

      // console.log(
      //   'this.bookingState.currentBookingId',
      //   this.bookingState.currentBookingId
      // );
      this.socket.emit("firstUpdate", {
        bookingId: this.bookingState.currentBookingId
      });

      // this.socket.on('bookingUpdate', (data) => {
      this.socket.on("hello", () => {
        // console.log('thsi is data', data);
        this.socket.emit("firstUpdate", {
          bookingId: this.bookingState.currentBookingId
        });
      });
      this.socket.on("booking:update", data => {
        // console.log('booking update!', data);
        this.refreshDocsSocket(data);
        if (this.bookingState && this.bookingState.infoText.closeDialog) {
          setTimeout(() => {
            // this.resetBookingState();
            console.log("reset booking state");
          }, 5000);
        }
      });
    }
    // if (this.bookingState.routeLink && !this.$route.name == 'referals') {
    //   this.$router.push(this.bookingState.routeLink);
    // }
  }
};
</script>
