// import { $host } from '../../../libs/user-api';
// import _ from 'lodash';
// import moment from 'moment';
// import store from '../..';

// const getDefaultState = () => {
//   return {
//     booking: false,
//     infoText: {
//       text: '',
//       closeDialog: false,
//       payButton: false,
//       reduceStatus: false,
//       title: '',
//       refreshButton: false,
//     },
//     cityId: null,
//     currentAparmentBookingId: null,
//     dateAt: null,
//     timeAt: null,
//     dateTo: null,
//     timeTo: null,
//     step: null,
//     error: null,
//     payId: null,
//     UTM: {
//       utm_source: null,
//       utm_medium: null,
//       utm_campaign: null,
//       utm_content: null,
//       utm_term: null,
//       utm_place: null,
//       utm_refer: null,
//     },
//     referalLink: [],
//     dayPrice: null,
//     sumText: null,
//     days: null,
//     apartmentInteres: false,
//     franchInteres: false,
//     currentApartmentTarif: null,
//     currentApartmentTarifTitle: '',
//     reservedTimes: null,
//     reasonsToDecline: [],
//   };
// };
// const state = getDefaultState();

// export default {
//   state: {
//     booking: state,
//     apartments: [],
//     apartment: {
//       id: null,
//       status: null,
//       title: '',
//       stringParams: '',
//       type: 'apartment',
//       //   gosNumber: null,
//       apartmentCityId: null,
//       // carClass: carClass,
//       // transmission: transmission,
//       // fuel: fuel,
//       // price: car.values[10],
//       // // imgLink: _.get(car, 'values[151][0].url', ''),
//       //   description: '',
//       //   priceDescription: '',
//       apartmentAdresInfo: null,
//       filialId: null,
//       ownerId: null,
//       price: null,
//       tariff: null,
//       //   carFilialWorkingHours: [],
//       //   carDeliveryAvalible: null,
//       //   carDeliveryNonWorkHours: [],
//       //   carPlacesDelivery: [],
//       // photos: photos
//     },
//     filters: {
//       cityId: null,
//       dateAt: moment().format('YYYY-MM-DD'),
//       //   statuses: [],
//       priceMin: 0,
//       priceMax: 0,
//       amountOfRoomsMin: 0,
//       amountOfRoomsMax: 0,
//       areaMin: 0,
//       areaMax: 0,
//       levelMin: 0,
//       levelMax: 0,
//       levelsTotalMin: 0,
//       levelsTotalMax: 0,
//       bedPlacesMin: 0,
//       bedPlacesMax: 0,
//       //   tariff: [],
//       //   tranmissions: [],
//       //   carClasses: [],
//       //   models: [],
//       //   fuels: [],
//       //   carProductYear: null,
//       //   carAdres: [],
//       visible: false,
//     },
//   },
//   mutations: {
//     clearApartmentFilter(state) {
//       state.filters.priceMin = '';
//       state.filters.priceMax = '';
//       state.filters.amountOfRoomsMin = '';
//       state.filters.amountOfRoomsMax = '';
//       state.filters.areaMin = '';
//       state.filters.areaMax = '';
//       state.filters.levelMin = '';
//       state.filters.levelMax = '';
//       state.filters.levelsTotalMin = '';
//       state.filters.levelsTotalMax = '';
//       state.filters.bedPlacesMin = '';
//       state.filters.bedPlacesMax = '';
//     },
//     resetBookingApartmentState(state) {
//       Object.assign(state.booking, getDefaultState());
//     },
//     updateApartments(state, apartments) {
//       state.apartments = apartments;
//     },
//     updateApartmentInteres(state, bool) {
//       state.booking.apartmentInteres = bool;
//     },
//     updateFranchApartmentInteres(state, bool) {
//       state.booking.franchInteres = bool;
//     },
//     updateApartmentStep(state, step) {
//       state.booking.step = step;
//     },
//     updateApartmentCurrentTarif(state, obj) {
//       state.booking.currentApartmentTarif = obj;
//     },
//     updateApartmentCurrentTarifTitle(state, string) {
//       state.booking.currentApartmentTarifTitle = string;
//     },
//     updateApartmentPayId(state, hash) {
//       state.booking.payId = hash;
//     },
//     updateApartmentDateAt(state, date) {
//       state.booking.dateAt = date;
//     },
//     updateApartmentDateTo(state, date) {
//       state.booking.dateTo = date;
//     },
//     updateApartmentTimeAt(state, time) {
//       state.booking.timeAt = time;
//     },
//     updateApartmentTimeTo(state, time) {
//       state.booking.timeTo = time;
//     },
//     updateBookingCityId(state, id) {
//       state.booking.cityId = id;
//     },
//     updateApartmentCurrentBookingId(state, id) {
//       state.booking.currentAparmentBookingId = id;
//     },
//     updateApartmentBooking(state, bool) {
//       state.booking.booking = bool;
//     },
//     updateDayPrice(state, num) {
//       state.booking.dayPrice = num;
//     },
//     updateErrorApartmentState(state, error) {
//       state.booking.error = error;
//     },
//     updateSumText(state, string) {
//       state.booking.sumText = string;
//     },
//     updateDays(state, num) {
//       state.booking.days = num;
//     },
//     updateApartmentBookingInfoText(state, obj) {
//       state.booking.infoText = obj;
//     },
//     // clearFilterApartments(state) {
//     //   //   state.filters.statuses = [];
//     //   state.filters.price = 0;
//     //   state.filters.price = 0;
//     //   state.filters.amountOfRoomsMin = 0;
//     //   state.filters.amountOfRoomsMax = 0;
//     //   state.filters.areaMin = 0;
//     //   state.filters.areaMax = 0;
//     //   state.filters.levelMin = 0;
//     //   state.filters.levelMax = 0;
//     //   state.filters.levelsTotalMin = 0;
//     //   state.filters.levelsTotalMax = 0;
//     //   state.filters.bedPlacesMin = 0;
//     //   state.filters.bedPlacesMax = 0;
//     //   //   state.filters.tranmissions = [];
//     //   //   state.filters.tariff = [];
//     //   //   state.filters.carClasses = [];
//     //   //   state.filters.models = [];
//     //   //   state.filters.fuels = [];
//     //   //   state.filters.carAdres = [];
//     //   //   state.filters.carProductYear = null;
//     // },
//     updateApartment(state, apartment) {
//       state.apartment = apartment;
//     },
//     updateReservedTimesApartment(state, data) {
//       state.booking.reservedTimes = data;
//     },
//     updateReasonsToDeclineApartment(state, data) {
//       state.booking.reasonsToDecline = data;
//     },
//     // clearCars(state) {
//     //     state.cars = []
//     // }
//     // updateCarFilialWorkingHours(state, value) {
//     //   state.carFilialWorkingHours = value;
//     // },
//     // updateCarDeliveryAvalible(state, value) {
//     //   state.carDeliveryAvalible = value;
//     // },
//     // updateCarDeliveryNonWorkHours(state, value) {
//     //   state.carDeliveryNonWorkHours = value;
//     // },
//     // updateCarPlacesDelivery(state, value) {
//     //   state.carPlacesDelivery = value;
//     // },
//     // adaptCarVideo(state, width, height) {
//     //   if (state.car.photos[2].video) {
//     //     state.car.photos[2].video = state.car.photos[2].video.replace(
//     //       'width="100" height="100"',
//     //       `width="${width}" height="${height}"`
//     //     );
//     //   }
//     // },
//   },
//   actions: {
//     // async getCars(ctx) {
//     //    let cars = await $host.get(`http://192.168.1.54/freecars/cars`);
//     // //    console.log(cars.data);
//     //    ctx.commit('updateCars', cars.data )
//     // },
//     async discardApartmentBooking(ctx, id) {
//       try {
//         const { data } = await $host.post('/freeapartments/booking', {
//           apartmentId: this.state.cars.car.id,
//           userId: this.state.user.id || null,
//           step: 'discard',
//           reasonToDecline: id,
//           currentBookingId: this.state.apartments.booking
//             .currentAparmentBookingId,
//         });
//         ctx.commit('updateApartmentStep', data.step);
//       } catch (e) {
//         console.log(e);
//       }
//     },
//     async backApartmentStepper(ctx, step) {
//       // let { data } =
//       await $host.post(`/freeapartments/booking`, {
//         currentBookingId: this.state.apartments.booking
//           .currentAparmentBookingId,
//         goBackToStep: step,
//       });
//       ctx.commit('updateApartmentStep', step);
//       // if (step === 0) {
//       //   // ctx.commit('updateApartmentTimeAt', null);
//       // }
//     },
//     async getInfoAboutApartmentsBookingByUser(ctx) {
//       const LASTSTEP = 3;
//       // console.log('get apartment');
//       try {
//         const { data } = await $host.get('/auth/apartments');
//         // console.log('this is data from get apartment booking', data);
//         if (data) {
//           // if (data.utm) {
//           //   data.utm.utmMarks.forEach((x) => {
//           //     const field = Object.keys(x)[0];
//           //     const value = Object.values(x)[0];
//           //     ctx.commit('updateUTM', { field, value });
//           //   });
//           //   ctx.commit('updateFilialToshow', data.utm.filialToShow);
//           //   ctx.commit('updateReferalLink', data.utm.referalLink);
//           // }
//           ctx.commit('updateApartmentCurrentBookingId', data.currentBookingId);
//           ctx.commit('updateApartmentPayId', data.hash);
//           ctx.commit('updateBookingCityId', data.cityId);
//           if (data.error) ctx.commit('updateErrorApartmentState', data.error);
//           let { dateAt, dateTo } = data;
//           // ctx.commit('updateCityId', data.cityId);
//           if (dateAt) {
//             ctx.commit(
//               'updateApartmentDateAt',
//               moment(dateAt).format('YYYY-MM-DD')
//             );
//             ctx.commit('updateApartmentTimeAt', moment(dateAt).format('HH:mm'));
//           }
//           if (dateTo) {
//             ctx.commit(
//               'updateApartmentDateTo',
//               moment(dateTo).format('YYYY-MM-DD')
//             );
//             ctx.commit('updateApartmentTimeTo', moment(dateTo).format('HH:mm'));
//           }
//           //проверка статуса заявки
//           if (!data.step) ctx.commit('updateApartmentStep', 0);
//           if (data.step) ctx.commit('updateApartmentStep', data.step);
//           if (data.step == LASTSTEP) {
//             ctx.commit('updateApartmentBookingInfoText', data.lastStepInfo);
//           }
//           // if (data.infoText)
//           //   ctx.commit('updateApartmentBookingInfoText', data.infoText);
//           if (data.apartmentId) {
//             console.log('dataapartment Id!!,', data.apartmentId);
//             store.dispatch('getApartmentById', data.apartmentId);
//             ctx.commit('updateApartmentBooking', true);
//             // await this.actions.getApartmentById(data.apartmentId);
//             // let car = await $host.get(`/freecars/car`, {
//             //   params: {
//             //     carId: data.carId,
//             //   },
//             // });
//             // ctx.commit('updateInfoText', data.infoText);
//             // ctx.commit('updateCar', data.car);
//             // ctx.commit('updateBookingState', data.car);
//             // ctx.commit('updatePayId', data.hash);
//             // ctx.commit('updateErrorState', data.errorMessage);
//             // ctx.commit('updatePlaceToPick', placeToPick);
//             // ctx.commit('updatePlaceToDrop', placeToDrop);
//             // // console.log('data from gertting booking:', data)
//             // ctx.commit('updateChosenServices', data.services);
//             // if (data.discount) {
//             //   ctx.commit('updateDiscount', {
//             //     field: (data.discount.type == 2 && 'procent') || 'fixedValue',
//             //     value: data.discount.price,
//             //   });
//             //   ctx.commit('updateDiscount', {
//             //     field: 'title',
//             //     value: data.discount.title,
//             //   });
//             // }
//             // ctx.commit('updatePriceText', data.priceText);
//             return data;
//           }
//           // else console.log('user has no apartmentId in booking');
//         }
//         // else console.log('user has no booking');
//         return null;
//       } catch (e) {
//         console.log('error with getting appartments', e);
//       }
//     },
//     async getDetailsApartmentFilialsTime(ctx) {
//       if (!ctx.getters.bookingApartment.dateAt) return;
//       const response = await $host.get('freecars/branches/branch', {
//         params: {
//           dateAt: ctx.getters.bookingApartment.dateAt,
//           filialId: ctx.getters.apartment.filialId,
//           assetType: 'apartment',
//         },
//       });
//       ctx.commit('updateReservedTimesApartment', response.data.reservedTimes);
//       ctx.commit(
//         'updateReasonsToDeclineApartment',
//         response.data.reasonsToDecline
//       );
//     },
//     async refreshApartmentInfoDocuments(ctx) {
//       try {
//         const { data } = await $host.get('/auth/apartments');
//         if (!data) {
//           ctx.commit('updateApartmentBooking', false);
//           ctx.commit('updateStep', null);
//           return;
//         }
//         ctx.commit('updateApartmentBookingInfoText', data.lastStepInfo);
//         ctx.commit('updateApartmentPayId', data.hash);
//         if (data.error) ctx.commit('updateErrorApartmentState', data.error);
//       } catch (e) {
//         console.log('eror on refhresh:::', e);
//       }
//     },
//     async getApartmentsByCityId(ctx, { cityId, dateAt }) {
//       let apartments = await $host.get(`/freeapartments/apartments`, {
//         params: {
//           cityId: cityId,
//           date: dateAt,
//         },
//       });
//       ctx.commit('updateApartments', apartments.data);
//     },
//     async createInterest(ctx, interesInvseting) {
//       let createRecord = await $host.post(`/freeapartments/invest`, {
//         interesInvseting: interesInvseting,
//         userId: this.state.user.id,
//         cityId: ctx.state.filters.cityId,
//         whatsappIsDefaultChannel: this.state.user.whatsappIsDefaultChannel,
//         phone: this.state.user.phone,
//         utm_source: this.state.booking.UTM.utm_source,
//         utm_medium: this.state.booking.UTM.utm_medium,
//         utm_campaign: this.state.booking.UTM.utm_campaign,
//         utm_content: this.state.booking.UTM.utm_content,
//         utm_term: this.state.booking.UTM.utm_term,
//         utm_place: this.state.booking.UTM.utm_place,
//         utm_refer: this.state.booking.UTM.utm_refer,
//         referalLink: this.state.booking.referalLink,
//       });
//       if (createRecord.data) {
//         interesInvseting
//           ? ctx.commit('updateApartmentInteres', true)
//           : ctx.commit('updateFranchApartmentInteres', true);
//         return createRecord.data;
//       }
//       return null;
//       // ctx.commit('updateApartments', apartments.data);
//     },
//     async getApartmentById(ctx, apartmentId) {
//       const isNan = Number.isNaN(parseInt(apartmentId));
//       if (!isNan) {
//         try {
//           let apartment = await $host.get(`/freeapartments/apartment`, {
//             params: {
//               apartmentId: apartmentId,
//             },
//           });
//           ctx.commit('updateApartment', apartment.data);
//           return apartment.statusText;
//         } catch (e) {
//           return null;
//         }
//       } else return null;
//     },
//     async createApartmentBookig(ctx) {
//       // const LASTSTEP = 3;
//       // const step = this.state.apartments.booking.step
//       // if (step == 1) ctx.dispatch('reserveApartament');
//       let createRecord = await $host.post(`/freeapartments/booking`, {
//         apartmentAdresInfo: this.state.apartments.apartment.apartmentAdresInfo,
//         userId: this.state.user.id,
//         whatsappIsDefaultChannel: this.state.user.whatsappIsDefaultChannel,
//         phone: this.state.user.phone,
//         utm_source: this.state.apartments.booking.UTM.utm_source,
//         utm_medium: this.state.apartments.booking.UTM.utm_medium,
//         utm_campaign: this.state.apartments.booking.UTM.utm_campaign,
//         utm_content: this.state.apartments.booking.UTM.utm_content,
//         utm_term: this.state.apartments.booking.UTM.utm_term,
//         utm_place: this.state.apartments.booking.UTM.utm_place,
//         utm_refer: this.state.apartments.booking.UTM.utm_refer,
//         referalLink: this.state.apartments.referalLink,
//         apartmentId: this.state.apartments.apartment.id,
//         filialId: this.state.apartments.apartment.filialId,
//         cityId: this.state.apartments.apartment.apartmentCityId,
//         currentBookingId: this.state.apartments.booking
//           .currentAparmentBookingId,
//         step: this.state.apartments.booking.step,
//         dateAt: this.state.apartments.booking.dateAt,
//         dateTo: this.state.apartments.booking.dateTo,
//         timeAt: this.state.apartments.booking.timeAt,
//         timeTo: this.state.apartments.booking.timeTo,
//         dayPrice: this.state.apartments.booking.dayPrice,
//         sumText: this.state.apartments.booking.sumText,
//         daysAmount: this.state.apartments.booking.daysAmount,
//         userTitle: this.state.user.title,
//         userBirthday: this.state.user.birthday,
//         userPassport: this.state.user.passport,
//         userPassportDate: this.state.user.passportDate,
//         userFilesPassportFront: this.state.user.files.passportFront,
//         userFilesPassportBack: this.state.user.files.passportBack,
//       });
//       if (createRecord.data.error)
//         ctx.commit('updateErrorApartmentState', createRecord.data.error);
//       // if (createRecord.data.step == LASTSTEP)
//       //   return await this.getInfoAboutApartmentsBookingByUser(ctx);
//       ctx.commit('updateApartmentBooking', true);
//       ctx.commit('updateApartmentStep', createRecord.data.step);
//       ctx.commit(
//         'updateApartmentCurrentBookingId',
//         createRecord.data.currentBookingId
//       );
//       if (createRecord.data.infoText)
//         ctx.commit(
//           'updateApartmentBookingInfoText',
//           createRecord.data.infoText
//         );
//       return createRecord && createRecord.data;
//     },
//     async reserveApartament(ctx) {
//       //чтобы сбросить предыдущее состояние
//       if (
//         this.state.apartments.booking.step != 1
//         // && this.state.apartments.booking.step !== null
//       ) {
//         return;
//       }
//       ctx.commit('updateErrorApartmentState', false);
//       let response = await $host.post(`/freeapartments/reserve`, {
//         dateAt: this.state.apartments.booking.dateAt,
//         timeAt: this.state.apartments.booking.timeAt,
//         dateTo: this.state.apartments.booking.dateTo,
//         timeTo: this.state.apartments.booking.timeTo,
//         apartmentId: this.state.apartments.apartment.id,
//         userId: this.state.user.id,
//         timezone: this.state.filials.currentFilial.timezone,
//         minutesBetweenBooking: this.state.filials.currentFilial
//           .minutesBetweenBookingApartment,
//       });
//       if (response.data == 'ok') {
//         //  ctx.commit('updateBookingState')
//         return true;
//       } else {
//         // console.log("here");
//         ctx.commit('updateErrorApartmentState', response.data);
//         return false;
//       }
//     },
//     calculateApartmentRentPrice(ctx) {
//       function daySpelling(num) {
//         if (num == 1) return 'день';
//         if (num == 2) return 'дня';
//         if (num == 3) return 'дня';
//         if (num == 4) return 'дня';
//         return 'дней';
//       }
//       const dateAtMoment = moment(ctx.state.booking.dateAt);
//       let daysAmount = moment
//         .duration(moment(ctx.state.booking.dateTo).diff(dateAtMoment))
//         .as('days');
//       daysAmount = daysAmount ? daysAmount : 1;
//       // Если есть тариф - смотрим есть ли в сменах тарифа указанная дата начала аренды и если есть применяем этот тариф
//       if (this.state.apartments.apartment.tariff) {
//         let tarifToUse = this.state.apartments.apartment.tariff;
//         ctx.commit('updateApartmentCurrentTarifTitle', '');
//         for (
//           let i = 0;
//           i < this.state.filials.currentFilial.filialTarifsHut.length;
//           i++
//         ) {
//           if (
//             dateAtMoment.isBetween(
//               moment(this.state.filials.currentFilial.filialTarifsHut[i].start),
//               moment(this.state.filials.currentFilial.filialTarifsHut[i].end),
//               undefined,
//               '[)'
//             )
//           ) {
//             tarifToUse = this.state.filials.currentFilial.filialTarifsHut[i]
//               .tarif;
//             ctx.commit(
//               'updateApartmentCurrentTarifTitle',
//               `в период с ${moment(
//                 ctx.getters.currentFilial.filialTarifsHut[i].start
//               ).format('DD.MM')} по ${moment(
//                 ctx.getters.currentFilial.filialTarifsHut[i].end
//               ).format('DD.MM')}`
//             );
//             break;
//           }
//         }
//         let dayPrice = this.state.apartments.apartment.price;
//         if (daysAmount == 1)
//           dayPrice =
//             Math.round(
//               (dayPrice - (dayPrice * tarifToUse.firstPeriod) / 100) / 10
//             ) * 10;
//         if ([2, 3].includes(daysAmount))
//           dayPrice =
//             Math.round(
//               (dayPrice - (dayPrice * tarifToUse.secondPeriod) / 100) / 10
//             ) * 10;
//         if ([3, 4, 5, 6, 7].includes(daysAmount))
//           dayPrice =
//             Math.round(
//               (dayPrice - (dayPrice * tarifToUse.thirdPeriod) / 100) / 10
//             ) * 10;
//         if ([8, 9, 10, 11, 12, 13, 14].includes(daysAmount))
//           dayPrice =
//             Math.round(
//               (dayPrice - (dayPrice * tarifToUse.fourthPeriod) / 100) / 10
//             ) * 10;
//         if (daysAmount > 14)
//           dayPrice =
//             Math.round(
//               (dayPrice - (dayPrice * tarifToUse.fivePeriod) / 100) / 10
//             ) * 10;
//         const rentPrice = dayPrice * daysAmount;
//         const textPrice = `Итого за ${daysAmount} ${daySpelling(
//           daysAmount
//         )} - ${rentPrice} руб.`;
//         ctx.commit('updateDayPrice', dayPrice);
//         ctx.commit('updateApartmentCurrentTarif', tarifToUse);
//         if (
//           ctx.state.booking.dateTo &&
//           ctx.state.booking.dateAt &&
//           rentPrice > 0
//         )
//           ctx.commit('updateSumText', textPrice);
//         rentPrice && rentPrice > 0 && ctx.commit('updateDays', daysAmount);
//       } else {
//         const rentPrice = this.state.apartments.apartment.price * daysAmount;
//         const textPrice = `Итого за ${daysAmount} ${daySpelling(
//           daysAmount
//         )} - ${rentPrice} руб.`;
//         ctx.commit('updateDayPrice', this.state.apartments.apartment.price);
//         if (
//           ctx.state.booking.dateTo &&
//           ctx.state.booking.dateAt &&
//           rentPrice > 0
//         )
//           ctx.commit('updateSumText', textPrice);
//         rentPrice && rentPrice > 0 && ctx.commit('updateDays', daysAmount);
//       }
//     },
//   },
//   getters: {
//     allApartments: (state) => {
//       let filteredApartments = state.apartments;
//       if (state.filters.city)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.cityLink[0].recordId == state.filters.city;
//         });
//       //   if (state.filters.statuses.length)
//       //     filteredApartments = _.filter(filteredApartments, (car) => {
//       //       return state.filters.statuses.includes(car.status.id);
//       //     });
//       if (state.filters.priceMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.price >= state.filters.priceMin;
//         });
//       if (state.filters.priceMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.price <= state.filters.priceMax;
//         });
//       if (state.filters.amountOfRoomsMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.amountOfRooms >= state.filters.amountOfRoomsMin;
//         });
//       if (state.filters.amountOfRoomsMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.amountOfRooms <= state.filters.amountOfRoomsMax;
//         });
//       if (state.filters.areaMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.area >= state.filters.areaMin;
//         });
//       if (state.filters.areaMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.area <= state.filters.areaMax;
//         });
//       if (state.filters.levelMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.level >= state.filters.levelMin;
//         });
//       if (state.filters.levelMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.level <= state.filters.levelMax;
//         });
//       if (state.filters.levelsTotalMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.levelsTotal >= state.filters.levelsTotalMin;
//         });
//       if (state.filters.levelsTotalMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.levelsTotal <= state.filters.levelsTotalMax;
//         });
//       if (state.filters.bedPlacesMin)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.bedPlaces >= state.filters.bedPlacesMin;
//         });
//       if (state.filters.bedPlacesMax)
//         filteredApartments = _.filter(filteredApartments, (o) => {
//           return o.bedPlaces <= state.filters.bedPlacesMax;
//         });
//       filteredApartments.sort((a, b) => {
//         if (a.status.id === b.status.id) {
//           return b.price - a.price;
//         }
//         return a.status.id > b.status.id ? 1 : -1;
//       });
//       return filteredApartments;
//       // return _.sortBy(
//       //   _.sortBy(filteredApartments, (o) => {
//       //     if ([2, 3].includes(+o.status.id))
//       //       return moment(o.status.dateAt).format('x');
//       //     return toString(o.price).replace(/\D+/g, '') * -1;
//       //   }),
//       //   (o) => {
//       //     return o.status.id;
//       //   }
//       // );
//     },
//     apartment: (state) => {
//       return state.apartment;
//     },
//     filtersApartment: (state) => {
//       return state.filters;
//     },
//     bookingApartment: (state) => {
//       return state.booking;
//     },
//     statusesApartment: (state) => {
//       return _.uniq(state.apartments.map((i) => i.status));
//     },
//     levelApartment: (state) => {
//       return _.uniq(state.apartments.map((i) => i.level));
//     },
//     minLevelApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.level));
//     },
//     maxLevelApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.level));
//     },
//     minLevelsTotalApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.levelsTotal));
//     },
//     maxLevelsTotalApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.levelsTotal));
//     },
//     minPriceApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.price));
//     },
//     maxPriceApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.price));
//     },
//     minAreaApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.area));
//     },
//     maxAreaApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.area));
//     },
//     minAmountOfRoomsApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.amountOfRooms));
//     },
//     maxAmountOfRoomsApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.amountOfRooms));
//     },
//     minBedPlacesApartment: (state) => {
//       return Math.min(...state.apartments.map((i) => i.bedPlaces));
//     },
//     maxBedPlacesApartment: (state) => {
//       return Math.max(...state.apartments.map((i) => i.bedPlaces));
//     },
//     levelsTotalApartment: (state) => {
//       return _.uniq(state.apartments.map((i) => i.levelsTotal));
//     },
//     bedPlacesApartment: (state) => {
//       return _.uniq(state.apartments.map((i) => i.bedPlaces));
//     },
//     amountOfRoomsApartment: (state) => {
//       return _.uniq(state.apartments.map((i) => i.amountOfRooms));
//     },
//     // apartmentClasses: (state) => {
//     //   return _.uniq(state.cars.map((i) => i.carClass));
//     // },
//     // tranmissions: (state) => {
//     //   return _.uniq(state.cars.map((i) => i.transmission));
//     // },
//     // models: (state) => {
//     //   return _.uniq(state.cars.map((i) => i.model));
//     // },
//     // fuels: (state) => {
//     //   return _.uniq(state.cars.map((i) => i.fuel));
//     // },
//     // carAdres: (state) => {
//     //   return _.uniq(state.cars.map((i) => i.carAdres.recordTitle));
//     // },
//   },
// };
export default {};
