const moment = require('moment');

const infoOnLastStep = (
  status,
  delivery = 1,
  deliveryTime,
  prePay,
  payTime,
  dayPrice,
  docflag,
  hasInspectionsPhotos
) => {
  if (status == 6)
    return {
      title: 'Ваша заявка отменена',
      textDelivery: '',
      textPay:
        'Спасибо за пользование услугами А-прокат. Ждём вас в следующий раз! Хорошего Вам дня!',
      closeDialog: true,
      progress: {
        text: 'Ваша заявка отменена',
        procent: 100,
      },
    };
  if (status == 5)
    return {
      title: 'Ваша заявка завершена',
      textDelivery: '',
      textPay:
        'Спасибо за пользование услугами А-прокат. Ждём вас в следующий раз! Хорошего Вам дня!',
      closeDialog: true,
      progress: {
        text: 'Ваша заявка завершена',
        procent: 100,
      },
    };
  const now = moment();
  const deliveryUntil = moment(deliveryTime);
  let payUntil = moment(payTime);
  const message = {
    title: '',
    textDelivery: '',
    textPay: '',
    payButton: false,
    refreshButton: true,
    inspectionUploadButton: false,
    inspectionResultButton: false,
    closeDialog: false,
    reduceStatus: false,
    progress: {
      show: true,
      procent: 0,
      text: '',
    },
  };
  message.title = 'Уважаемый клиент!';

  if (docflag == 1 || docflag == 2) {
    message.title += ' Получили Ваши документы.';
    message.textDelivery +=
      '👌 Сейчас все оформим, после чего в Whatsapp придет сообщение с подтверждением бронирования и адресом куда подойти. Обычно это занимает не более 5 минут в рабочее время. Мы работаем с 05 до 21 МСК. Для получения актуальной информации просто обновите страницу';
    message.progress.procent = 12;
    message.progress.text = 'Проверка документов';

    return message;
  }
  if (delivery == 3) {
    message.textDelivery +=
      'Доставка на указанный в Вашей заявке адрес - согласована.\n';
    message.progress.procent = 60;
  }
  if (delivery == 2) {
    const minutesToAgree = moment(deliveryUntil.diff(now)).format('H:m');
    message.textDelivery += `Доставка на указанный в Вашей заявке адрес в процессе согласования, мы согласуем её в течении ${
      minutesToAgree.split(':')[0] == '0'
        ? `${minutesToAgree.split(':')[1]} минут`
        : `${minutesToAgree.split(':')[0]} часов ${
            minutesToAgree.split(':')[1]
          } минут`
    } или быстрее.`;
    message.progress.procent = 30;
    message.progress.text = 'Согласовываем доставку';
    // \nПо истечению этого срока или раньше Вам придет сообщение в ватсап, либо вы можете обновить страницу для получения актуальной информации о статусе согласования.\n
  }
  if (prePay == 2 || prePay == 1) {
    if ((payUntil.isBefore(now) && payTime) || (prePay == 1 && !payTime)) {
      message.payButton = false;
      message.textPay =
        'К сожалению время Вашей оплаты истекло. Попробуйте забронировать машину заново.';
      message.reduceStatus = true;
      message.progress.show = false;
    } else {
      if (!payTime) {
        payUntil = now.add(40, 'minutes');
      }
      const minutesToPay = moment(payUntil.diff(now)).format('H:m');
      message.textPay += `Для подтверждения бронирования внесите оплату в течение ${
        minutesToPay.split(':')[0] == '0'
          ? `${minutesToPay.split(':')[1]} минут`
          : `${minutesToPay.split(':')[0]} часов ${
              minutesToPay.split(':')[1]
            } минут`
      } за весь период бронирования или минимум ${1000} руб. При отмене бронирования минимум за 24 часа до аренды, оплата остаётся на вашем счёте.`;
      message.payButton = true;
      message.progress.procent = 80;
      message.progress.text = 'Ожидание оплаты';
    }
  }
  if (prePay == 3) {
    message.textPay += `Оплата получена. Машина ${
      delivery == 2 ? 'предварительно' : ''
    } забронирована.`;
    // message.progress.show = false;
  }
  if (prePay == 4) {
    message.textPay += `Машина ${
      delivery == 2 ? 'предварительно' : ''
    } забронирована.`;
    // message.progress.show = false;
  }
  if (prePay == 5) {
    message.textPay += `Оплатить заявку договорились в офисе. Машина ${
      delivery == 2 ? 'предварительно' : ''
    } забронирована.`;
    // message.progress.show = false;
  }
  if (
    (prePay == 3 || prePay == 4 || prePay == 5) &&
    (delivery == 3 || delivery == 4 || delivery == 1)
  ) {
    message.refreshButton = false;
    message.title = 'Машина забронирована.';
    // message.inspectionUploadButton = true
    message.progress.show = false;
  }
  if (!message.textDelivery && !message.textPay) {
    message.textDelivery =
      '👌 Сейчас все оформим, после чего в Whatsapp придет сообщение с подтверждением бронирования и адресом куда подойти. Обычно это занимает не более 5 минут в рабочее время. Мы работаем с 05 до 21 МСК.';

    message.progress.procent = 30;
    message.progress.text = 'Проверка документов...';
  }

  // Инспекции. Если сдает авто, или если нет результатов осмотра, то загрузить осмотр,
  // иначе - показать результаты

  if (
    status == 7 ||
    (!hasInspectionsPhotos &&
      (((prePay == 3 || prePay == 4 || prePay == 5) &&
        (delivery == 3 || delivery == 4 || delivery == 1)) ||
        status == 3))
  ) {
    message.progress.show = false;
    message.inspectionUploadButton = true;
    message.title += '\nСделайте осмотр';
  }
  if (hasInspectionsPhotos && status != 7) {
    message.progress.show = false;
    message.inspectionResultButton = true;
  }
  return message;
};

module.exports = infoOnLastStep;
