<template>
  <div>
    <div v-if="showCodeField">
    <v-text-field
      type="number"
      solo
      placeholder="Введите код"
      outlined
      :dark="false"
      v-mask="mask"
      v-model="code"
      class="white--text mb-3 centered-input-code px-2"
      :disabled="disableArea"
      :error="!!codeError"
      :error-messages="codeError"
    />
    </div>
    <div class="px-2">
      <v-progress-linear
        v-if="disableArea"
        indeterminate
        color="green darken-2"
      ></v-progress-linear>
    </div>
    <div class="codePlaceholderBlock" v-if="!disableArea"></div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  props: {
    hide: {
      type: Function,
      default: () => {
        console.log('default click');
      },
    },
    fromRestore: {
      type: Boolean,
      default: false,
    },
    showCodeField: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    mask: [/\d/, /\d/, /\d/, /\d/],
    code: null,
    disableArea: false,
    codeError: '',
  }),
  computed: {
    readyToCheck() {
      return this.code && this.code.toString().length == 4;
    },
    ...mapGetters(['socket', 'bookingState']),
  },
  watch: {
    readyToCheck: {
      immediate: true,
      handler: async function(a) {
        if (a) {
          this.disableArea = true;
          const checkCodeResult = await this.checkAuthCode({
            code: this.code,
            restore: this.fromRestore,
          });

          if (checkCodeResult.success) {
            if (this.fromRestore) {
              await this.getUser();
              this.disableArea = false;
              this.socket.on('booking:update', (data) => {
                this.refreshDocsSocket(data);
                if (this.bookingState.infoText.closeDialog) {
                  setTimeout(() => {
                    this.resetBookingState();
                  }, 5000);
                }
              });
              this.$router.push({
                name: 'login',
                query: {
                  token: '1234'
                },
              });
              this.hide()
              this.updateShowAuthForm(false)
            } else if (!this.fromRestore) {
              await this.getUser();
              this.disableArea = false;
              this.socket.on('booking:update', (data) => {
                this.refreshDocsSocket(data);
                if (this.bookingState.infoText.closeDialog) {
                  setTimeout(() => {
                    this.resetBookingState();
                  }, 5000);
                }
              });
              // this.updateBookingState();
              // await this.getBooking();
              this.updateStep(1);

              // this.hide()
            }
          } else {
            this.disableArea = false;
            this.codeError = checkCodeResult.message;
            this.code = '';
            setTimeout(() => {
              this.codeError = '';
            }, 1500);
          }
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'checkAuthCode',
      'getBooking',
      'getUser',
      'refreshDocsSocket',
      'resetBookingState',
    ]),
    ...mapMutations(['updateStep', 'updateBookingState', 'updateShowAuthForm']),
  },
  mounted: async function() {},
  updated: function() {},
};
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.codePlaceholderBlock {
  display: block;
  height: 4px;
}
.centered-input-code {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          text-align: center;
        }
      }
    }
  }
}
</style>
