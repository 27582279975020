import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
// import LoadScript from "vue-plugin-load-script";


// import './registerServiceWorker'
// import { $host } from './libs/user-api';
// import axios from 'axios';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueDadata from 'vue-dadata';
// import VueSocketIO from 'vue-socket.io';
// import SocketIO from 'socket.io-client';

// window.onerror = function(message, source, line, column, error) {
//   axios.post('/log/front', {
//     message,
//     source,
//     line,
//     column,
//     error,
//   });
// };

// Vue.use(
//   new VueSocketIO({
//     debug: false,
//     connection: SocketIO('http://localhost:8080', {
//       query: { userId: 25937 },
//     }),
//     vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_',
//     },
//     // options: , //Optional options
//   })
// );

Vue.config.productionTip = false;
// Vue.use(require("moment"));
// Vue.use(require("lodash"));

// Vue.config.errorHandler = function(err, vm, info) {
//   axios.post('/log/front', {
//     err,
//     id: vm._id,
//     info,
//   });
// };

new Vue({
  sockets: {
    connect: function () {
      console.log('socket connected');
    },
    bookingUpdate: function (data) {
      console.log('this is data:::', data);
    },
  },
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
Vue.use(VueMask);
Vue.use(VueYandexMetrika, {
  id: 44968180,
  env:
    window.origin == 'https://app.a-prokat.com' ? 'production' : 'development',
  router: router,
  debug: window.origin != 'https://app.a-prokat.com',
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  trackHash: true,
  ecommerce: 'dataLayer',
});
Vue.use(VueDadata);
// Vue.use(LoadScript);
// Vue.loadScript("https://tagmanager.andata.ru/api/v1/container/9b7ba201-f90b-4c3f-a8b4-fbcf8124cf94/published/code.js")

