import moment from 'moment';
import { $host } from '../../libs/user-api';
import { filter } from 'lodash';
export default {
  state: {
    carOperations: [],
    inspections: [],
    myOperations: {
      all: [],
      total: [],
    },
    filters: {
      cars: [],
    },
    filtersInspections: {
      cars: [],
    },
    myCars: [],
    hints: {},
    // myTotal: [],
  },
  mutations: {
    clearFilter(state) {
      state.filters.cars = [];
    },
    updateCarOperations(state, operations) {
      state.carOperations = operations;
    },
    updateCarInspections(state, inspections) {
      state.inspections = inspections;
    },
    updateMyOperations(state, operations) {
      state.myOperations = operations;
    },
    updateMyCars(state, cars) {
      state.myCars = cars;
    },
    updateHints(state, hints) {
      state.hints = hints;
    },
    // updateMyTotal(state, operations) {
    //   state.myTotal = operations;
    // },
  },
  actions: {
    async getMyCars(ctx) {
      let response = await $host.get(`/api/cars_settings`);
      if (response.data) ctx.commit('updateMyCars', response.data.cars);
      if (response.data) ctx.commit('updateHints', response.data.hints);
    },
    async updateFieldInCar(
      ctx,
      { carId = null, fieldName = null, value = null }
    ) {
      let response = await $host.post(`/api/cars_settings`, {
        carId,
        fieldName,
        value,
      });
      if (response.data) return true;
    },
    async getCarOperations(ctx, dates) {
      let dateAtX = moment(dates[0]).format('x');
      let dateToX = moment(dates[1]).format('x');
      let response = await $host.get(
        `/api/cars_refils?dt_start=${dateAtX}&dt_stop=${dateToX}`
      );
      if (response.data) ctx.commit('updateCarOperations', response.data);
    },
    async getCarInspections(ctx, dates) {
      let dateAtX = moment(dates[0]).format('x');
      let dateToX = moment(dates[1]).format('x');
      let response = await $host.get(
        `/api/cars_inspections?dt_start=${dateAtX}&dt_stop=${dateToX}`
      );
      if (response.data) ctx.commit('updateCarInspections', response.data);
    },
    async getMyOperations(ctx, dates) {
      let dateAtX = moment(dates[0]).format('x');
      let dateToX = moment(dates[1]).format('x');
      let response = await $host.get(
        `/api/user_refils?dt_start=${dateAtX}&dt_stop=${dateToX}`
      );
      if (response.data) ctx.commit('updateMyOperations', response.data);
      // const allOperations = {}
      // for (let index in response.data) {
      //   if (!allOperations[response.data[index].article]) allOperations[response.data[index].article] = response.data[index].sum
      //   else allOperations[response.data[index].article] += response.data[index].sum
      // }
      // const allOperationsArray = []
      // for (let key in allOperations) {
      //   allOperationsArray .push({article: key, sum: allOperations[key]})
      // }
      // ctx.commit('updateMyTotal', allOperationsArray);
    },
  },
  getters: {
    carOperations(state) {
      let cars = state.carOperations.carsRefills;
      if (cars) {
        let filterCars = cars;
        if (state.filters.cars.length) {
          // filterCars = filterCars.filter((c) => {
          //   console.log('this is c', c);
          //   console.log('this is state.filters.cars', state.filters.cars);
          //   return state.filters.cars.includes(c.title);
          // });
          filterCars = filter(filterCars, (o) => {
            return state.filters.cars.includes(o.car);
          });
        }
        console.log('filterCars', filterCars.length);
        return { cars: state.carOperations.cars, carsRefills: filterCars };
      }
      return state.carOperations;
    },
    myOperations(state) {
      return state.myOperations;
    },
    myCars(state) {
      return state.myCars;
    },
    carHints(state) {
      return state.hints;
    },
    carInspections(state) {
      if (state.filtersInspections.cars.length) {
        let insp = state.inspections.inspections;
        if (state.filtersInspections.cars.length) {
          // filterCars = filterCars.filter((c) => {
          //   console.log('this is c', c);
          //   console.log('this is state.filters.cars', state.filters.cars);
          //   return state.filters.cars.includes(c.title);
          // });
          insp = filter(insp, (o) => {
            return state.filtersInspections.cars.includes(o.title);
          });
        }
        return insp;
      }
      return state.inspections.inspections;
    },
    filtersOperations: (state) => {
      return state.filters;
    },
    carNameForOperations: (state) => {
      if (state.carOperations.cars)
        return state.carOperations.cars.map((i) => i.title);
      return [];
    },
    carNameForInspections: (state) => {
      if (state.inspections.carNames) return state.inspections.carNames;
      return [];
    },
    filtersInspections: (state) => {
      return state.filtersInspections;
    },
  },
};
