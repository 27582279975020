import { $host } from '../../libs/user-api';
import { get, has } from 'lodash';
import moment from 'moment';
import infoOnLastStep from '../../../server/utils/infoOnLastStep';
import Cookies from 'js-cookie';
// import resultValues from '../../libs/resultValues';

const getDefaultState = () => {
  return {
    booking: false,
    error: false,
    dateAt: null,
    timeAt: null,
    dateTo: null,
    timeTo: null,
    services: [],
    shownServices: [],
    // carId: 'null'
    // buttonVisible: false
    placeToPick: null,
    placeToDrop: null,
    adresToPick: null,
    adresToDrop: null,
    chosenServices: [],
    mandatoryServices: [],
    priceForDay: null,
    priceText: '',
    step: null,
    comment: '',
    unfinishedBookings: [],
    reservedTimes: null,
    cityId: null,
    status: null,
    reasonsToDecline: [],
    currentTarif: null,
    currentTarifTitle: '',
    UTM: {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      utm_term: null,
      utm_place: null,
      utm_refer: null,
    },
    ridLink: [],
    referalLink: [],
    promocode: [],
    routeLink: null,
    cityLink: null,
    filialToShow: null,
    filialFromUtms: null,
    hideFilials: false,
    discounts: {
      procent: 0,
      fixedValue: 0,
      title: '',
      input: '',
      oneDayApply: { type: Boolean, default: true },
    },
    infoText: {
      title: '',
      textDelivery: '',
      textPay: '',
      payButton: false,
      refreshButton: true,
      inspectionUploadButton: false,
      inspectionResultButton: false,
      reduceStatus: false,
      closeDialog: false,
      progress: {
        show: false,
        procent: 0,
        text: '',
      }, //
    },
    currentBookingId: null,
    payId: '',
    inspections: {
      required: {
        inspectFront: {},
        inspectRight: {},
        inspectLeft: {},
        inspectBack: {},
        inspectFuelLevel: {},
        inspectTrunk: {},
        // inspectSelfie: {},
        act: {},
      },
      carDamage: [],
      oldPhotos: [],
    },
    showInspection: false,
    carInteres: false,
    franchInteres: false,
    referalInteres: false,
  };
};
const state = getDefaultState();

export default {
  state,
  mutations: {
    resetBookingState(state) {
      Object.assign(state, getDefaultState());
    },
    updateBookingState(state, car) {
      if (car) this.state.cars.car = car;
      state.booking = !state.booking;
      state.error = false;
      // if (!state.booking) {
      //     state.dateAt = null,
      //     state.timeAt = null,
      //     state.dateTo = null
      // }
    },
    handleInspections(state) {
      state.showInspection = !state.showInspection;
    },
    updateCarInteres(state, bool) {
      state.carInteres = bool;
    },
    updateFranchCarInteres(state, bool) {
      state.franchInteres = bool;
    },
    updateReferalInteres(state, bool) {
      state.referalInteres = bool;
    },
    updateInspections(state, { field, link, title }) {
      state.inspections.required[field].link = link;
      state.inspections.required[field].title = title;
    },
    updateCarDamageInspections(state, array) {
      state.inspections.carDamage = array;
    },
    updateOldInspections(state, array) {
      state.inspections.oldPhotos = array;
    },
    changeInspection(state, { field, file }) {
      state.inspections.required[field] = file;
    },
    changeDamageInspection(state, { index, file }) {
      state.inspections.carDamage[index] = file;
    },
    updateDiscount(state, { field, value }) {
      state.discounts[field] = value;
    },
    updatePayId(state, hash) {
      state.payId = hash;
    },
    updateInfoText(state, obj) {
      state.infoText = obj;
    },
    updateCurrentBookingId(state, id) {
      state.currentBookingId = id;
    },
    updateUTM(state, { field, value }) {
      state.UTM[field] = value;
    },
    updateFilialToshow(state, filial) {
      state.filialToShow = filial;
    },
    updateFilialFromUtms(state, filial) {
      state.filialFromUtms = filial;
    },
    updateCurrentTarif(state, obj) {
      state.currentTarif = obj;
    },
    updateCurrentTarifTitle(state, string) {
      state.currentTarifTitle = string;
    },
    updateDateAt(state, date) {
      state.dateAt = date;
    },
    updateDateTo(state, date) {
      state.dateTo = date;
    },
    updateTimeAt(state, time) {
      state.timeAt = time;
    },
    updateTimeTo(state, time) {
      state.timeTo = time;
    },
    updateErrorState(state, msg) {
      state.error = msg;
    },
    updateButtonVisible(state, visible) {
      state.buttonVisible = visible;
    },
    updateServices(state, services) {
      state.services = services;
    },
    updateShownServices(state, services) {
      state.shownServices = services;
    },
    updatePlaceToPick(state, place) {
      state.placeToPick = place;
    },
    updatePlaceToDrop(state, place) {
      state.placeToDrop = place;
    },
    updateAdresToPick(state, adres) {
      state.adresToPick = adres;
    },
    updateAdresToDrop(state, adres) {
      state.adresToDrop = adres;
    },
    updateChosenServices(state, service) {
      state.chosenServices = service;
    },
    updateMandatoryServices(state, service) {
      state.mandatoryServices = service;
    },
    updatePriceText(state, text) {
      state.priceText = text;
    },
    updatePriceForDay(state, price) {
      state.priceForDay = price;
    },
    updateComment(state, comment) {
      state.comment = comment;
    },
    updateStep(state, step) {
      state.step = step;
    },
    updateUnfinishedBookings(state, array) {
      state.unfinishedBookings = array;
    },
    updateReservedTimes(state, times) {
      state.reservedTimes = times;
    },
    updateCityId(state, cityId) {
      state.cityId = cityId;
    },
    updateStatus(state, status) {
      state.status = status;
    },
    updateReasonsToDecline(state, array) {
      state.reasonsToDecline = array;
    },
    updateReferalLink(state, array) {
      state.referalLink = array;
    },
    updateRidLink(state, array) {
      state.ridLink = array;
    },
    updatePromocodeLink(state, array) {
      state.promocode = array;
    },
    updateRouteLink(state, routeLink) {
      state.routeLink = routeLink;
    },
    updateCityLink(state, cityLink) {
      state.cityLink = cityLink;
    },
    updateYm_uid(state, ym_uid) {
      state.ym_uid = ym_uid;
    },
    updateUbtcuid(state, ubtcuid) {
      state.ubtcuid = ubtcuid;
    },
    updateHideFilials(state, bool) {
      state.hideFilials = bool;
    },
    manipulateInspections(state, obj) {
      state.infoText.inspectionUploadButton = obj.inspectionUploadButton;
      state.infoText.inspectionResultButton = obj.inspectionResultButton;
    },
  },
  actions: {
    async getOldPhotos(ctx) {
      const { data } = await $host.get('/inspections/oldPhotos', {
        params: {
          currentBookingId: this.state.booking.currentBookingId,
          carId: this.state.cars.car.id,
          userId: this.state.user.id,
        },
      });
      ctx.commit('updateOldInspections', data.oldPhotosLink);
    },
    async postInspectionsFileKeys(ctx) {
      // perepisat
      async function uploadFilesToBP(file, field) {
        let fileKeysData = await $host.post('/inspections/fileKeys', {
          name: file.name,
          type: file.type,
          size: file.size,
        });

        let fileKeys = fileKeysData.data;
        const formData = new FormData();
        formData.append('key', fileKeys.fileKey);
        formData.append('acl', 'private');
        formData.append('AWSAccessKeyId', fileKeys.AWSAccessKeyId);
        formData.append('Policy', fileKeys.police);
        formData.append('Signature', fileKeys.signature);
        formData.append('Content-Type', fileKeys.mimeType);
        formData.append('file', file);
        try {
          await $host.post(fileKeys.uploadUrl, formData);
        } catch (e) {
          console.log(e);
        }

        let link = `${fileKeys.uploadUrl}/${fileKeys.fileKey}`;
        let title = fileKeys.name;
        const obj = { field, link, title };
        return obj;
      }

      const tempStoreOfFileSizes = [];
      let flag = true;
      const iterablePromiseArrayRequired = [];
      const iterablePromiseArrayCarDamage = [];
      const CarDamageArray = [];
      for (const field in this.state.booking.inspections.required) {
        let file = this.state.booking.inspections.required[field];
        const typeOfFile = file.type ? file.type : 'image';
        const sizeOfFile = file.size ? file.size : 1;
        if (typeOfFile.indexOf('image') !== 0) {
          file = null;
        }
        if (sizeOfFile > 50 * 1024 * 1024) {
          file = null;
        }

        if (tempStoreOfFileSizes.indexOf(sizeOfFile) == -1 && file.name) {
          tempStoreOfFileSizes.push(sizeOfFile);
          iterablePromiseArrayRequired.push(uploadFilesToBP(file, field));
        } else if (!file.link) {
          this.state.booking.inspections.required[field] = null;
          if (field != 'act') flag = false;
        }
      }
      const results = await Promise.allSettled(iterablePromiseArrayRequired);
      results.forEach((result) => {
        if (result.reason) flag = false;
        else {
          const { field, link, title } = result.value;
          ctx.commit('updateInspections', { field, link, title });
        }
      });
      if (this.state.booking.inspections.carDamage.length) {
        for (const index in this.state.booking.inspections.carDamage) {
          console.log(
            'this is carDamage array',
            this.state.booking.inspections.carDamage
          );
          let file = this.state.booking.inspections.carDamage[index];
          const typeOfFile = file.type ? file.type : 'image';
          const sizeOfFile = file.size ? file.size : 1;
          if (typeOfFile.indexOf('image') !== 0) {
            file = null;
          }
          if (sizeOfFile > 50 * 1024 * 1024) {
            file = null;
          }

          if (tempStoreOfFileSizes.indexOf(sizeOfFile) == -1 && file.name) {
            tempStoreOfFileSizes.push(sizeOfFile);
            iterablePromiseArrayCarDamage.push(uploadFilesToBP(file, index));
          } else if (!file.link) {
            this.state.booking.inspections.carDamage[index] = null;
            flag = false;
          }
        }
        const results = await Promise.allSettled(iterablePromiseArrayCarDamage);
        results.forEach((result) => {
          if (result.reason) flag = false;
          else {
            const { field, link, title } = result.value;
            CarDamageArray.push({ field, link, title });
            // ctx.commit('updateCarDamageInspections', { field, link, title });
          }
        });
        // console.log('this is carDamageArray', CarDamageArray);
        if (flag) ctx.commit('updateCarDamageInspections', CarDamageArray);
        // console.log('this is state', this.state);
      }

      return flag;
    },
    async saveInspections() {
      const { data } = await $host.post('/inspections/saveInspection', {
        inspectionsRequired: this.state.booking.inspections.required,
        inspectionsCarDamage: this.state.booking.inspections.carDamage,
        filialId: this.state.filials.currentFilial.filialId,
        carId: this.state.cars.car.id,
        userId: this.state.user.id,
        currentBookingId: this.state.booking.currentBookingId,
      });
      // console.log('this is data!!!', data);
      return data.finished;
    },
    async saveInspectionsMore() {
      const { data } = await $host.post('/inspections/saveInspection', {
        // inspectionsRequired: this.state.booking.inspections.required,
        morePhotos: this.state.booking.inspections.morePhotos,
        filialId: this.state.filials.currentFilial.filialId,
        carId: this.state.cars.car.id,
        userId: this.state.user.id,
        currentBookingId: this.state.booking.currentBookingId,
        inspectionId: this.state.booking.inspections.inspectionId,
      });
      // console.log('this is data!!!', data);
      return data.finished;
    },
    async getUTM(ctx, obj) {
      const { data } = await $host.get('/freecars/utm', { params: obj });
      data.utmMarks.forEach((x) => {
        const field = Object.keys(x)[0];
        const value = Object.values(x)[0];
        ctx.commit('updateUTM', { field, value });
      });
      const { ym_uid, ubtcuid } = obj
      console.log('ym_uid:', ym_uid);
      console.log('ubtcuid:', ubtcuid);
      const {
        filialToShow,
        referalLink,
        hideFilials,
        ridLink,
        routeLink,
        promocode,
        filialFromUtms,
        cityLink,
      } = data;
      ctx.commit('updateFilialToshow', filialToShow);
      ctx.commit('updateFilialFromUtms', filialFromUtms);
      ctx.commit('updateReferalLink', referalLink);
      ctx.commit('updateRidLink', ridLink);
      ctx.commit('updateHideFilials', hideFilials);
      ctx.commit('updateRouteLink', routeLink);
      ctx.commit('updateCityLink', cityLink);
      ctx.commit('updateUbtcuid', ubtcuid);
      ctx.commit('updateYm_uid', ym_uid);
      return filialToShow;
    },
    checkAndUpdateCookie(ctx, obj) {
      console.log('checkAndUpdateCookie');
      console.log(Cookies.get('_ubtcuid'));
      if (!this.booking || !('ubtcuid' in this.booking) || !(this.booking.ubtcuid)) {
        const ubtcuid = Cookies.get('_ubtcuid');
        ctx.commit('updateUbtcuid', ubtcuid);
      }
      if (!this.booking || !('ym_uid' in this.booking) || !(this.booking.ym_uid)) {
        const ym_uid = Cookies.get('_ym_uid')
        ctx.commit('updateYm_uid', ym_uid);
      }
    },

    async checkAndGetUTM(ctx, obj) {
      // console.log('this.state.booking.cityLink', this.state.booking.cityLink);
      if (!this.state.booking.cityLink) {
        for (let key in obj) {
          if (key.toLowerCase().includes('utm')
            || key.toLowerCase() == 'rid'
            || key.toLowerCase() == 'channel'
            || key.toLowerCase() == 'ubtcuid'
            || key.toLowerCase() == 'ym_uid'
          ) {
            // await this.getUTM(obj);
            await ctx.dispatch('getUTM', obj);
            break;
          }
        }
      }
    },
    async getDetailsFilialsTime(ctx) {
      if (!this.state.booking.dateAt || !this.state.booking.dateAt) return;
      const response = await $host.get('freecars/branches/branch', {
        params: {
          dateAt: this.state.booking.dateAt,
          filialId: this.state.cars.car.filialId,
          assetType: 'car',
        },
      });
      ctx.commit('updateReservedTimes', response.data.reservedTimes);
      ctx.commit('updateReasonsToDecline', response.data.reasonsToDecline);
    },
    async reserveCar(ctx) {
      //чтобы сбросить предыдущее состояние
      if (this.state.booking.step != 1 && this.state.booking.step !== null)
        return;
      ctx.commit('updateErrorState', false);
      let response = await $host.post(`/freecars/reserve`, {
        dateAt: this.state.booking.dateAt,
        timeAt: this.state.booking.timeAt,
        dateTo: this.state.booking.dateTo,
        timeTo: this.state.booking.timeTo,
        carId: this.state.cars.car.id,
        userId: this.state.user.id,
        timezone: this.state.filials.currentFilial.timezone,
        minutesBetweenBooking: this.state.filials.currentFilial
          .minutesBetweenBooking,
      });
      if (response.data == 'ok') {
        //  ctx.commit('updateBookingState')
      } else {
        // console.log("here");
        ctx.commit('updateErrorState', response.data);
      }
    },
    async backStepper(ctx, step) {
      try {
        const { data } = await $host.post('/auth/booking', {
          currentBookingId: this.state.booking.currentBookingId,
          userId: this.state.user.id,
          goBackToStep: step,
        });
        if (data.step) ctx.commit('updateStep', data.step);
        if (!data || !data.step)
          ctx.commit(
            'updateErrorState',
            data
              ? data.error
              : 'Ваша заявка находится в обработке, обновите страницу'
          );
      } catch (e) {
        console.log(e);
      }
    },
    async discardBooking(ctx, id = 7) {
      try {
        await $host.post('/auth/booking', {
          carId: this.state.cars.car.id,
          carGosNumber: this.state.cars.car.gosNumber,
          userId: this.state.user.id || null,
          step: 'discard',
          reasonToDecline: id,
          currentBookingId: this.state.booking.currentBookingId,
        });
        ctx.commit('updateUnfinishedBookings', []);
        // ctx.commit('updateStep', data.step);
      } catch (e) {
        console.log(e);
      }
    },

    refreshDocsSocket(ctx, payload) {
      const getValues = (obj, name) => {
        return has(obj.values, `${name}`)
          ? get(obj.values, `${name}`)
          : get(obj, `prevValues.${name}`, null);
      };
      // const values = resultValues(payload);
      // const textObject = infoOnLastStep(
      //   values[2][0],
      //   values[199][0],
      //   values[200],
      //   values[168][0],
      //   values[201],
      //   values[106],
      //   values[203][0],
      //   // !!values[140].length
      //   !!_.get(values, '[140][0]')
      // );
      const text = getValues(payload, 'textPrice');
      if (text) ctx.commit('updatePriceText', text);
      const status = getValues(payload, 'status[0]');
      const servicesLink = getValues(payload, 'servicesLink');
      if (servicesLink.length) {
        servicesLink.forEach((x) => (x.id = x.recordId));
        const serv = [];
        for (let i = 0; i < servicesLink.length; i++) {
          serv.push({ id: servicesLink[i].recordId });
        }
        ctx.commit('updateChosenServices', serv);
      }
      const textObject = infoOnLastStep(
        status,
        getValues(payload, 'deliveryStatus[0]'),
        getValues(payload, 'deliveryUntilDate'),
        getValues(payload, 'paymentStatus[0]'),
        getValues(payload, 'paymentUntilDate'),
        getValues(payload, 'priceForDay'),
        getValues(payload, 'documentsCheckStatus[0]'),
        !!getValues(payload, 'inspectionsLink[0]')
      );
      ctx.commit('updateInfoText', textObject);
      if (status) ctx.commit('updateStatus', status);
    },

    // refreshStoreBySocket(ctx, data) {
    //   const result = resultValues(data)
    //   if ("textPrice" in result) {
    //     ctx.commit('updatePriceText', result['textPrice'])
    //   }
    // },

    async refreshInfoDocuments(ctx) {
      try {
        const { data } = await $host.get('/auth/booking');
        ctx.commit('updateInfoText', data.infoText);
        ctx.commit('updatePayId', data.hash);
      } catch (e) {
        console.log('eror on refhresh:::', e);
      }
    },

    async getBooking(ctx) {
      try {
        const { data } = await $host.get('/auth/booking');
        if (data) {
          if (data.utm) {
            data.utm.utmMarks.forEach((x) => {
              const field = Object.keys(x)[0];
              const value = Object.values(x)[0];
              ctx.commit('updateUTM', { field, value });
            });
            ctx.commit('updateFilialToshow', data.utm.filialToShow);
            ctx.commit('updateReferalLink', data.utm.referalLink);
            if (data.utm.ridLink) ctx.commit('updateRidLink', data.utm.ridLink);
            if (data.utm.routeLink)
              ctx.commit('updateRouteLink', data.utm.routeLink);
            ctx.commit('updateHideFilials', data.utm.hideFilials);
          }
          ctx.commit('updateCurrentBookingId', data.currentBookingId);
          let {
            dateAt,
            dateTo,
            bookingOffcet,
            placeToPick,
            placeToDrop,
            adresToDrop,
            adresToPick,
          } = data;
          const userOffcet = moment().utcOffset();
          ctx.commit('updateUnfinishedBookings', data.bookings);
          ctx.commit('updateCityId', data.cityId);
          if (dateAt) {
            dateAt = moment(dateAt).add(bookingOffcet - userOffcet, 'minutes');
            ctx.commit('updateDateAt', dateAt.format('YYYY-MM-DD'));
            ctx.commit('updateTimeAt', dateAt.format('HH:mm'));
          }
          if (dateTo) {
            dateTo = moment(dateTo).add(bookingOffcet - userOffcet, 'minutes');
            ctx.commit('updateDateTo', dateTo.format('YYYY-MM-DD'));
            ctx.commit('updateTimeTo', dateTo.format('HH:mm'));
          }
          //проверка статуса заявки
          if (!data.step) ctx.commit('updateStep', 0);
          if (data.step) ctx.commit('updateStep', data.step);
          if (data.status) ctx.commit('updateStatus', data.status);
          if (data.promocode) ctx.commit('updatePromocodeLink', data.promocode);

          if (data.carId) {
            // let car = await $host.get(`/freecars/car`, {
            //   params: {
            //     carId: data.carId,
            //   },
            // });
            ctx.commit('updateInfoText', data.infoText);
            ctx.commit('updateCar', data.car);
            ctx.commit('updateBookingState', data.car);
            ctx.commit('updatePayId', data.hash);
            ctx.commit('updateErrorState', data.errorMessage);
            ctx.commit('updatePlaceToPick', placeToPick);
            ctx.commit('updatePlaceToDrop', placeToDrop);
            ctx.commit('updateAdresToPick', adresToPick);
            ctx.commit('updateAdresToDrop', adresToDrop);
            // console.log('data from gertting booking:', data)
            ctx.commit('updateChosenServices', data.services);
            if (data.discount) {
              ctx.commit('updateDiscount', {
                field: (data.discount.type == 2 && 'procent') || 'fixedValue',
                value: data.discount.price,
              });
              ctx.commit('updateDiscount', {
                field: 'title',
                value: data.discount.title,
              });
              ctx.commit('updateDiscount', {
                field: 'oneDayApply',
                value: data.discount.oneDayApply == 2,
              });
            }
            ctx.commit('updatePriceText', data.priceText);
            return data;
          }
          // else console.log('user has no carId in booking');
        }
        // else console.log('user has no booking');
        return null;
      } catch (e) {
        console.log('error with getting booking', e);
      }
    },
    async directlyPatch(ctx, string) {
      if (string == 'placeToPick') {
        const { data } = await $host.post('/auth/booking/direct', {
          currentBookingId: this.state.booking.currentBookingId,
          placeToPick: this.state.booking.placeToPick,
        });
        console.log('this is data::', data);
        console.log('ctx', ctx);
      }
    },
    async checkCode(ctx, valueFromClient) {
      // let lastBookingId =
      //   this.state.booking &&
      //   this.state.booking.unfinishedBookings &&
      //   this.state.booking.unfinishedBookings.length > 0
      //     ? this.state.booking.unfinishedBookings[
      //         this.state.booking.unfinishedBookings.length - 1
      //       ].id
      //     : null;
      const { data } = await $host.post('/freecars/promo', {
        promocode: valueFromClient,
        currentBookingId: this.state.booking.currentBookingId,
      }),
        { promoDiscount } = data;

      if (promoDiscount) {
        const hasAlready = ctx.state.chosenServices.find(
          (x) => x.id == promoDiscount.recordId
        );

        if (!hasAlready) {
          ctx.commit('updateChosenServices', [
            ...ctx.state.chosenServices,
            promoDiscount,
          ]);
        }

        // ctx.commit('updateReferalLink', referalLink);
        // const discountValue = promoDiscount.values[4];
        // const discountType =
        //   (promoDiscount.values[3][0] == 2 && 'procent') || 'fixedValue';
        // const discountApplyOneDay = promoDiscount.values[8][0] == 2;
        // ctx.commit('updateDiscount', {
        //   field: discountType,
        //   value: discountValue,
        // });
        // ctx.commit('updateDiscount', {
        //   field: 'title',
        //   value: promoDiscount.values[2],
        // });
        // ctx.commit('updateDiscount', {
        //   field: 'input',
        //   value: valueFromClient,
        // });
        // ctx.commit('updateDiscount', {
        //   field: 'oneDayApply',
        //   value: discountApplyOneDay,
        // });
      }
      return data;
    },
    async createInterestCar(ctx, interesInCar) {
      const { data } = await $host.post('/auth/booking', {
        investing: true,
        interesInCar: interesInCar,
        userId: this.state.user.id,
        cityId: this.state.cars.filters.cityId,
        whatsappIsDefaultChannel: this.state.user.whatsappIsDefaultChannel,
        phone: this.state.user.phone,
        utm_source: this.state.booking.UTM.utm_source,
        utm_medium: this.state.booking.UTM.utm_medium,
        utm_campaign: this.state.booking.UTM.utm_campaign,
        utm_content: this.state.booking.UTM.utm_content,
        utm_term: this.state.booking.UTM.utm_term,
        utm_place: this.state.booking.UTM.utm_place,
        utm_refer: this.state.booking.UTM.utm_refer,
        referalLink: this.state.booking.referalLink,
      });
      if (data) {
        interesInCar
          ? ctx.commit('updateCarInteres', true)
          : ctx.commit('updateFranchCarInteres', true);
      }
      return data;
    },
    async createReferalBooking(ctx, fromCarsPage = false) {
      const campaign = this.state.booking.UTM.utm_campaign
        ? this.state.booking.UTM.utm_campaign
        : fromCarsPage
          ? [{ catalogId: 130, recordId: 3151 }]
          : null;
      const { data } = await $host.post('/auth/booking', {
        investing: false,
        referalProgram: true,
        referalProgramStep: 1,
        userId: this.state.user.id,
        whatsappIsDefaultChannel: this.state.user.whatsappIsDefaultChannel,
        phone: this.state.user.phone,
        utm_source: this.state.booking.UTM.utm_source,
        utm_medium: this.state.booking.UTM.utm_medium,
        utm_campaign: campaign,
        utm_content: this.state.booking.UTM.utm_content,
        utm_term: this.state.booking.UTM.utm_term,
        utm_place: this.state.booking.UTM.utm_place,
        utm_refer: this.state.booking.UTM.utm_refer,
        referalLink: this.state.booking.referalLink,
        filialFromUtms: this.state.booking.filialFromUtms,
        cityToShow: this.state.booking.cityLink
          ? this.state.booking.cityLink
          : this.state.cities.currentCityId
            ? [{ catalogId: 57, recordId: this.state.cities.currentCityId }]
            : [],
      });
      ctx.commit('updateReferalInteres', true);
      return data;
    },
    async createReferalLinkFromReferalPage(ctx, obj) {
      console.log('this si ctx', ctx);
      const { data } = await $host.post('/auth/booking', {
        investing: false,
        referalProgram: true,
        referalProgramStep: 2,
        userId: this.state.user.id,
        referalSelfBookingId: this.state.user.referalSelfBookingId,
        cityId: obj.cityId,
        refTypeId: obj.refTypeId,
      });
      return data.status == 'success';
    },
    async checkAuthCode(ctx, obj) {
      const response = await $host.post('/auth/code', {
        code: obj.code,
        currentBookingId: ctx.state.currentBookingId,
        restore: obj.restore,
      });
      return response.data;
    },
    async startBooking(ctx) {
      async function pause(timer = 500) {
        return new Promise(function (resolve) {
          setTimeout(() => {
            resolve();
          }, timer);
        });
      }
      try {
        //check if user has booking
        if (
          this.state.booking.dateAt &&
          this.state.booking.timeAt &&
          this.state.booking.dateTo &&
          this.state.booking.timeTo &&
          (this.state.booking.step != 1 || this.state.booking.step != null)
        ) {
          // console.log('user have car in booking, check avalible')
          let response = await $host.post(`/freecars/reserve`, {
            dateAt: this.state.booking.dateAt,
            timeAt: this.state.booking.timeAt,
            dateTo: this.state.booking.dateTo,
            timeTo: this.state.booking.timeTo,
            carId: this.state.cars.car.id,
            currentBookingId: this.state.booking.currentBookingId,
            userId: this.state.user.id,
            timezone: this.state.filials.currentFilial.timezone,
            minutesBetweenBooking: this.state.filials.currentFilial
              .minutesBetweenBooking,
          });
          if (response.data == 'ok') {
            // console.log('car is avalible');
          } else {
            // console.log('car is not avalible');
            ctx.commit('updateStep', 1);
            ctx.commit('updateErrorState', response.data);
            return;
          }
        }
        // console.log('this is step!!', this.state.booking.step);
        // let lastBookingId =
        //   this.state.booking &&
        //   this.state.booking.unfinishedBookings &&
        //   this.state.booking.unfinishedBookings.length > 0
        //     ? this.state.booking.unfinishedBookings[
        //         this.state.booking.unfinishedBookings.length - 1
        //       ].id
        //     : null;
        // console.log('THIS STATE!0', this.state)
        const { data } = await $host.post('/auth/booking', {
          carId: this.state.cars.car.id,
          carStatusId:
            this.state.cars.car &&
            this.state.cars.car.status &&
            this.state.cars.car.status.id,
          carGosNumber: this.state.cars.car.gosNumber,
          filialId: this.state.cars.car.filialId,
          userId: this.state.user.id || null,
          whatsappIsDefaultChannel: this.state.user.whatsappIsDefaultChannel,
          utm_source: this.state.booking.UTM.utm_source,
          utm_medium: this.state.booking.UTM.utm_medium,
          utm_campaign: this.state.booking.UTM.utm_campaign,
          utm_content: this.state.booking.UTM.utm_content,
          utm_term: this.state.booking.UTM.utm_term,
          utm_place: this.state.booking.UTM.utm_place,
          utm_refer: this.state.booking.UTM.utm_refer,
          ym_uid: this.state.booking.ym_uid,
          ubtcuid: this.state.booking.ubtcuid,
          channel: this.state.booking.UTM.channel,
          referalLink: this.state.booking.referalLink,
          ridLink: this.state.booking.ridLink,
          promocode: this.state.booking.promocode,
          discountInput: this.state.booking.discounts.input,
          phone: this.state.user.phone,
          step: this.state.booking.step,
          deliveryPlaces: this.state.booking.deliveryPlaces,
          placeToDrop: this.state.booking.placeToDrop,
          placeToPick: this.state.booking.placeToPick,
          services: this.state.booking.services,
          mandatoryServices: this.state.booking.mandatoryServices,
          chosenServices: this.state.booking.chosenServices,
          comment: this.state.booking.comment,
          priceText: this.state.booking.priceText,
          priceForDay: this.state.booking.priceForDay,
          currentBookingId: this.state.booking.currentBookingId,
          dateAt: this.state.booking.dateAt,
          dateTo: this.state.booking.dateTo,
          timeAt: this.state.booking.timeAt,
          timeTo: this.state.booking.timeTo,
          userTitle: this.state.user.title,
          userBirthday: this.state.user.birthday,
          userPassport: this.state.user.passport,
          userPassportDate: this.state.user.passportDate,
          //   userPassportIssued: this.state.user.passportIssued,
          userFilesPassportFront: this.state.user.files.passportFront,
          userFilesPassportBack: this.state.user.files.passportBack,
          userFilesDriveLicenseFront: this.state.user.files.driveLicenseFront,
          userFilesDriveLicenseBack: this.state.user.files.driveLicenseBack,
          adresToPick: this.state.booking.adresToPick,
          adresToDrop: this.state.booking.adresToDrop,
          cityId: this.state.cities.currentCityId,
        });
        //проверка статуса заявки
        if (data.currentBookingId)
          ctx.commit('updateCurrentBookingId', data.currentBookingId);
        if (!data.step) ctx.commit('updateStep', 0);

        ctx.commit('updateErrorState', data.error);
        //обновление стора
        if (data.step == 4) {
          await pause(1000);
          const { data } = await $host.get('/auth/booking');
          ctx.commit('updateInfoText', data.infoText);
          ctx.commit('updatePayId', data.hash);
          ctx.commit('updateStatus', data.status);
        }
        if (data.step) ctx.commit('updateStep', data.step);
        // убрал в тестовом порядке
        // if (data.step) {
        //   const getBooking = await $host.get('/auth/booking');
        //   ctx.commit('updateUnfinishedBookings', getBooking.data.bookings);
        // }

        //проверка СБ
        if (data.securityStatus == 7) {
          ctx.commit('updateUnfinishedBookings', []);
        } //
        // if (data.infoText) ctx.commit('updateInfoText', data.infoText);
        // обновление стора при последнем шаге, для показа сообщения без обновления
        // if (data.step == 4) await this.refreshInfoDocuments(ctx);
        // console.log('this is data!!!', data);
        return data ? data : undefined;
      } catch (e) {
        console.log('error::', e);
      }
    },

    async getPriceData(ctx) {
      try {
        if (!this.state.cars.car.id) return;
        // let {
        //   deliveryTerms,
        //   deliveryPlaces,
        // } = this.state.filials.currentFilial;
        // const standartDeliveryPlace = deliveryPlaces.filter(
        //   (place) => place.values[9][0] == 2
        // )[0];
        // const serviceToShow = deliveryTerms.filter(
        //   (term) => term.values[17][0] == 2
        // );
        // ctx.commit("updateShownServices", serviceToShow);
        if (!this.state.booking.placeToPick)
          ctx.commit(
            'updatePlaceToPick',
            this.state.filials.currentFilial.deliveryPlaces[0]
          );
        if (!this.state.booking.placeToDrop)
          ctx.commit(
            'updatePlaceToDrop',
            this.state.filials.currentFilial.deliveryPlaces[0]
          );
      } catch (e) {
        console.log(e);
      }
    },
    async calculatePrice(ctx) {
      const mandatoryTermsId = [];
      this.state.filials.currentFilial.deliveryPlaces.forEach((x) => {
        //услуги зависящие от адреса
        if (
          x.id == ctx.state.placeToPick?.id &&
          x.values[5].length > 0 &&
          !mandatoryTermsId.includes(x.values[5][0])
        )
          mandatoryTermsId.push(x.values[5][0].recordId);
        if (
          x.id == ctx.state.placeToDrop?.id &&
          x.values[6].length > 0 &&
          !mandatoryTermsId.includes(x.values[6][0])
        )
          mandatoryTermsId.push(x.values[6][0].recordId);
      });
      let serviceWithDisabled = this.state.filials.currentFilial.deliveryTerms.map(
        (x) => {
          if (mandatoryTermsId.includes(x.id)) {
            x['disabled'] = true;
          } else x['disabled'] = false;

          return x;
        }
      );
      //массив услуг зависящих от адреса и/или времени
      const mandatoryTerms = this.state.filials.currentFilial.deliveryTerms.filter(
        (service) => mandatoryTermsId.includes(service?.id)
      );

      if (
        this.state.filials.currentFilial.deliveryNonWorkHours &&
        this.state.filials.currentFilial.deliveryNonWorkHours[0] &&
        ctx.state.timeAt &&
        this.state.filials.currentFilial.deliveryAtNight == 1 &&
        !moment(ctx.state.timeAt, 'HH:mm').isBetween(
          moment(this.state.filials.currentFilial.schedule[0], 'HH:mm'),
          moment(this.state.filials.currentFilial.schedule[1], 'HH:mm'),
          undefined,
          '[]'
        ) &&
        !mandatoryTerms.includes(
          this.state.filials.currentFilial.deliveryNonWorkHours[0]
        )
      ) {
        mandatoryTerms.push(
          this.state.filials.currentFilial.deliveryNonWorkHours[0]
        );
        const service = this.state.filials.currentFilial
          .deliveryNonWorkHours[0];
        service['disabled'] = true;
        serviceWithDisabled.push(service);
      }
      if (
        this.state.filials.currentFilial.deliveryNonWorkHours &&
        this.state.filials.currentFilial.deliveryNonWorkHours[1] &&
        ctx.state.timeTo &&
        this.state.filials.currentFilial.deliveryAtNight == 1 &&
        !moment(ctx.state.timeTo, 'HH:mm').isBetween(
          moment(this.state.filials.currentFilial.schedule[0], 'HH:mm'),
          moment(this.state.filials.currentFilial.schedule[1], 'HH:mm'),
          undefined,
          '[]'
        ) &&
        !mandatoryTerms.includes(
          this.state.filials.currentFilial.deliveryNonWorkHours[1]
        )
      ) {
        mandatoryTerms.push(
          this.state.filials.currentFilial.deliveryNonWorkHours[1]
        );
        const service2 = this.state.filials.currentFilial
          .deliveryNonWorkHours[1];
        service2['disabled'] = true;
        serviceWithDisabled.push(service2);
      }
      ctx.commit('updateServices', serviceWithDisabled);
      ctx.commit('updateMandatoryServices', mandatoryTerms);

      let dateAt = moment(this.state.booking.dateAt)
        .add(moment(this.state.booking.timeAt, 'HH:mm').hours(), 'hours')
        .add(moment(this.state.booking.timeAt, 'HH:mm').minutes(), 'minutes');

      let dateTo = moment(this.state.booking.dateTo)
        .add(moment(this.state.booking.timeTo, 'HH:mm').hours(), 'hours')
        .add(moment(this.state.booking.timeTo, 'HH:mm').minutes(), 'minutes');

      let { chosenServices, mandatoryServices } = this.state.booking;
      let { tariff, price } = this.getters.car;
      if (!tariff) tariff = this.state.cars.car.tariff;
      if (!price) price = this.state.cars.car.values[10];
      let hours = moment(dateTo).diff(moment(dateAt), 'hours');
      let hoursDiff = hours > 24 && hours % 24;
      let days =
        hoursDiff > 12 ? Math.round(hours / 24) : Math.floor(hours / 24) || 1;
      // let shouldStopTime = moment(dateTo)
      //   .startOf('day')
      //   .add(moment(dateAt).hour(), 'hours');
      // let stopTimeHour = moment(dateTo)
      //   .startOf('day')
      //   .add(moment(dateTo).hours(), 'hours');

      let ratio = 2;
      let rentPrice = tariff ? calculateRentPrice(price, days, dateAt) : price;
      ctx.commit('updatePriceForDay', rentPrice);
      let hoursPrice =
        hoursDiff <= 12 ? calculateHoursPrice(rentPrice, hoursDiff) : 0;
      let hoursText = `\nСтоимость доп. ${hoursDiff} часов:\n ${hoursPrice} руб.`;
      let sum = days * rentPrice + hoursPrice;
      function calculateHoursPrice(rentPrice, hoursDiff) {
        if (hoursDiff > 1) {
          let sum = Math.floor((rentPrice / 24) * hoursDiff * ratio);
          if (sum < rentPrice) return sum;
          else return rentPrice;
        } else return 0;
      }
      function calculateRentPrice(rentPrice, days, dateAt) {
        let tarifToUse = ctx.getters.car.tariff;
        ctx.commit('updateCurrentTarifTitle', '');
        for (
          let i = 0;
          i < ctx.getters.currentFilial.filialTarifsAuto.length;
          i++
        ) {
          if (
            dateAt.isBetween(
              moment(
                ctx.getters.currentFilial.filialTarifsAuto[i].start
              ).startOf('day'),
              moment(ctx.getters.currentFilial.filialTarifsAuto[i].end).endOf(
                'day'
              ),
              undefined,
              '[)'
            )
          ) {
            tarifToUse = ctx.getters.currentFilial.filialTarifsAuto[i].tarif;
            ctx.commit(
              'updateCurrentTarifTitle',
              `в период с ${moment(
                ctx.getters.currentFilial.filialTarifsAuto[i].start
              ).format('DD.MM')} по ${moment(
                ctx.getters.currentFilial.filialTarifsAuto[i].end
              ).format('DD.MM')}`
            );
            break;
          }
        }
        ctx.commit('updateCurrentTarif', tarifToUse);
        if (days == 1)
          return (
            Math.round(
              (rentPrice - (rentPrice * tarifToUse.firstPeriod) / 100) / 10
            ) * 10
          );
        if ([2, 3].includes(days))
          return (
            Math.round(
              (rentPrice - (rentPrice * tarifToUse.secondPeriod) / 100) / 10
            ) * 10
          );
        if ([3, 4, 5, 6, 7].includes(days))
          return (
            Math.round(
              (rentPrice - (rentPrice * tarifToUse.thirdPeriod) / 100) / 10
            ) * 10
          );
        if ([8, 9, 10, 11, 12, 13, 14].includes(days))
          return (
            Math.round(
              (rentPrice - (rentPrice * tarifToUse.fourthPeriod) / 100) / 10
            ) * 10
          );
        if (days > 14)
          return (
            Math.round(
              (rentPrice - (rentPrice * tarifToUse.fivePeriod) / 100) / 10
            ) * 10
          );
      }

      function calculateServices(chosenServices, mandatory) {
        //объект с ценами, текстом и айдишником услуг
        const servicesValue = { price: [], text: '', ids: [] };
        chosenServices.forEach((element) => {
          if (!servicesValue.ids.includes(element.id)) {
            if (element.values && element.values[12][0] == 1) {
              servicesValue.ids.push(element.id);
              servicesValue.text += `${element.values[2].split('=')[0]}:\n  ${element.values[4]
                } руб.\n`;
              servicesValue.price.push(element.values[4]);
            } else if (element.type == 1) {
              servicesValue.ids.push(element.id);
              servicesValue.text += `${element.title.split('=')[0]}:\n  ${element.price
                } руб.\n`;
              servicesValue.price.push(element.price);
            }
          }
        });
        mandatory.forEach((element) => {
          if (!servicesValue.ids.includes(element.id)) {
            servicesValue.ids.push(element.id);
            servicesValue.text += `${element.values[2].split('=')[0]}:\n  ${element.values[4]
              } руб.\n`;
            servicesValue.price.push(element.values[4]);
          }
        });

        servicesValue.total = servicesValue.price.reduce((a, b) => a + b, 0);

        return servicesValue;
      }

      //Скидки

      const serviceSum = calculateServices(chosenServices, mandatoryServices);
      const serviceText = serviceSum.text ? `${serviceSum.text}` : '';
      const carPice = `Стоимость ${days} суток аренды:\n ${days *
        rentPrice} руб.${hoursPrice ? '\n' + hoursText : ''} ${serviceText ? '\n' + serviceText : ''
        } ${this.state.booking.discounts.title
          ? '\n' +
          this.state.booking.discounts.title +
          `${this.state.booking.discounts.oneDayApply ? ' на один день' : ''}`
          : ''
        }
      \nИтого: ${this.state.booking.discounts.oneDayApply
          ? sum +
          rentPrice *
          ((100 - this.state.booking.discounts.procent) / 100 - 1) -
          this.state.booking.discounts.fixedValue +
          serviceSum.total
          : (sum * (100 - this.state.booking.discounts.procent)) / 100 -
          this.state.booking.discounts.fixedValue +
          serviceSum.total
        } руб.`;
      ctx.commit('updatePriceText', carPice);
    },
  },
  getters: {
    bookingState(state) {
      return state;
    },
    inspectionsRequired(state) {
      return state.inspections.required;
    },
    oldInspections(state) {
      return state.inspections.oldPhotos;
    },
    inspectionsDamage(state) {
      return state.inspections.carDamage;
    },
    bookingService(state) {
      return [
        ...new Map(
          [...state.chosenServices, ...state.mandatoryServices].map((item) => [
            item['id'],
            item,
          ])
        ).values(),
      ];
    },
  },
};
