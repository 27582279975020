<template>
  <div>
    <v-card class="elevation-12" max-width="600">
      <v-tabs model="tabs" grow>
        <v-tabs-slider color="purple darken-4"></v-tabs-slider>
        <v-tab v-for="i in tabs" :key="i.name">
          <v-icon left>{{ i.icon }}</v-icon>
          {{ i.name }}
        </v-tab>
        <v-tab-item>
          <v-card-text>
            <v-form v-model="valid" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                label="Телефон"
                v-model="phone"
                :rules="phoneRules"
                counter="11"
                required
                autofocus
                maxlength="11"
              ></v-text-field>
              <v-text-field
                id="passwod"
                label="Пароль"
                prepend-icon="mdi-lock"
                v-model="password"
                type="password"
                :rules="passwordRules"
                required
              ></v-text-field>
              <v-checkbox
                v-model="checkbox"
                :rules="[
                  (v) =>
                    !!v || 'Согласен с уловиями политики конфиденциальности',
                ]"
              >
                <template v-slot:label>
                  <div>
                    Согласен с
                    <a
                      target="_blank"
                      href="https://a-prokat.com/confidential"
                      @click.stop
                    >
                      политикой конфиденциальности
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="updateShowAuthForm(false)">Отмена</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="loginHandler(phone, password)"
              :disabled="!phone || !password || !checkbox"
              :loading="loading"
              >Войти</v-btn
            >
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-form ref="restore" v-model="valid" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                label="Телефон"
                v-model="phone"
                :rules="phoneRules"
                counter="11"
                required
                autofocus
                maxlength="11"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="restore(phone)"
              :disabled="!phone || !restorebuttonActive || phone.length != 11"
              :loading="loading"
              block
              >Подтвердить телефон</v-btn
            >
          </v-card-actions>
          <div v-if="showPopUp">
            <AuthCode :hide="goBack" :fromRestore="true"/>
          </div>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog v-model="user.errorMessage" width="500">
      <v-card>
        <v-card-title>Внимание</v-card-title>
        <v-card-text>
          {{ user.errorMessage }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert v-if="showPopUp" dense lazy>{{ message }}</v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AuthCode from './utils/AuthCode.vue';
// import SocketIO from 'socket.io-client';
export default {
  components: { AuthCode },
  data() {
    return {
      valid: true,
      phone: '',
      restorebuttonActive: true,
      phoneRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) =>
          (v && v.replace(/\D+/g, '').length == 11 && v[0] == 7) ||
          'Телефон должен содержать 11 цифр начиная с 7',
      ],
      password: '',
      passwordRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) =>
          (v && v.length >= 6) || 'Пароль должен содержать минимум 6 символов',
      ],
      checkbox: false,
      message: '',
      status: false,
      showPopUp: false,
      loading: false,
      tabs: [
        { name: 'Авторизация', icon: 'mdi-key' },
        { name: 'Задать пароль', icon: 'mdi-restore' },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapMutations(['updateShowAuthForm']),
    async loginHandler(phone, password) {
      this.loading = true;
      const login = await this.loginUser({ phone, password });
      const logging = await this.getUser();
      this.loading = false;
      if (!logging || !login) {
        // this.message =
        //   'Ошибка при входе, введите корректную пару телефон/пароль. При необходимости восстановите пароль.';
        // this.showPopUp = true;
        return;
      }
      this.updateShowAuthForm(false);
      await this.getBooking();
    },
    // async login(phone, password) {
    //   try {
    //     this.updatePhone(phone);
    //     this.updatePass(password);
    //     console.log("trying to login");
    //     let logging = await this.loginUser(phone, password);
    //     this.updatePass("");
    //     if (!logging) {
    //       this.message =
    //         "Ошибка при входе, введите корректную пару телефон/пароль. При необходимости восстановите пароль.";
    //       this.showPopUp = true;
    //     }

    //     if (logging?.data?.status === true) {
    //       this.$router.push("/");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    //,
    async restore(phone) {
      if (this.valid) {
        // this.updatePhone(phone);
        this.loading = true;
        let response = await this.restorePassword(phone);
        if (response) {
          this.message = response.message;
          this.status = response.status;
          this.showPopUp = true;
        }
        this.loading = false;
      }
    },
    goBack() {
      // this.goBackFunction()
      this.showPopUp = false;
      // this.updateBookingState();
    },
    ...mapActions([
      'restorePassword',
      'loginUser',
      'getBooking',
      // 'refreshDocsSocket',
      'getUser',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
