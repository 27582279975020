<template>
  <nav>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div v-if="client" class="grey--text">
        {{ notProduction ? 'RC ' : '' }}Баланс: {{ user.balance }} р.
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="profileButtonClick" v-if="!!name" :class="invest ? 'mr-2 px-0' : 'mr-3'">
        <v-icon>mdi-account</v-icon>
        <span> {{ name }}</span>
      </v-btn>
      <v-btn @click="appealClick" v-if="invest" class="mr-16 px-0">
        <v-icon>mdi-plus-box-outline</v-icon>
        <span> обращение</span>
      </v-btn>
      <!-- <v-btn @click="logout()" v-if="!!name">
        <span>выйти</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn> -->
      <v-btn @click="goToLogin" v-if="!name">
        <span>войти в аккаунт</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer">
      <v-list v-if="!invest">
        <v-subheader>Меню</v-subheader>
        <v-list-item v-for="(item, i) in listItems" :key="i" :router="!!item.route" :target="item.inNewTab && '_blank'"
          :to="(!(item.sub && item.sub.length) && item.route) || ''" @click="item.click || defaultClick"
          :disabled="item.disabled">
          <v-list-item-icon :class="item.sub && item.sub.length ? 'mr-0' : ''">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-group v-if="item.sub && item.sub.length" :value="true">
            <template v-slot:activator>
              <v-list-item-content class="subHeader">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="subItem" router v-for="(sub, x) in item.sub" :key="x" :to="sub.route"><v-list-item-icon>
                <v-icon v-text="sub.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="sub.title" class="subTitle"></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item-content v-if="!(item.sub && item.sub.length)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout" v-if="user.auth">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-subheader>Меню</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item to="/cars">
            <v-list-item-icon>
              <v-icon>mdi-car</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Бронирование</v-list-item-title>
          </v-list-item>

          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Мои финансы</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="([title, icon, route], i) in investMenu" :key="i" :to="route">
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Мои авто</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="([title, icon, route], i) in investAutoMenu" :key="i" :to="route">
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item to="/referals">
            <v-list-item-icon>
              <v-icon>mdi-car</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Рефералка</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" v-if="user.auth">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="showAppeal" width="500">
      <Appeal :goBack="appealClose" />
    </v-dialog>
  </nav>
  <!-- <div v-html="andataTagManagerCode"></div> -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Appeal from '../components/Appeal.vue';
export default {
  components: { Appeal },
  data() {
    return {
      drawer: false,
      investMenu: [
        ['Операции', 'mdi-cash-check', '/operations/my'],
        // ['Доход авто', 'mdi-car-arrow-right', '/operations/invest'],
        // ['Осмотры авто', 'mdi-car-connected', '/operations/car'],
      ],
      investAutoMenu: [
        // ['Мои операции', 'mdi-cash-check', '/operations/my'],
        ['Настройки', 'mdi-hanger', '/myCars/carSettings'],
        ['Доход', 'mdi-car-arrow-right', '/operations/invest'],
        ['Осмотры', 'mdi-car-connected', '/myCars/inspections'],
      ],
      showAppeal: false,
      selectedItem: true,
      listItems: [
        {
          title: 'Бронирование',
          icon: 'mdi-car',
          route: '/cars',
          click: () => {
            this.drawer = !this.drawer;
          },
          inNewTab: false,
          disabled: false,
        },
        {
          title: 'Финансы',
          icon: 'mdi-cash-check',
          route: '/operations/my',
          inNewTab: false,
          disabled: false,
        },
        {
          title: 'Рефералка',
          icon: 'mdi-account-multiple-plus',
          route: '/referals',
          inNewTab: false,
          disabled: false,
        },
        // {
        //   title: 'Выйти',
        //   icon: 'mdi-exit-to-app',
        //   route: '/logout',
        //   inNewTab: false,
        //   disabled: false,
        // },
        //    {
        //        title: "Заявки",
        //        icon: "mdi-briefcase-variant",
        //        route: '/bookings',
        //        disabled: true
        //    },
        // {
        // title: 'Обращения',
        // icon: 'mdi-android-messages',
        // route: '/messages',
        // disabled: true,
        // },
        // {
        //   title: 'Календарь',
        //   icon: 'mdi-update',
        //   route: '/calendar',
        //   disabled: false,
        // },
      ],
    };
  },
  methods: {
    ...mapMutations(['updateShowAuthForm']),
    appealClick() {
      this.showAppeal = true;
    },
    appealClose() {
      this.showAppeal = false;
    },
    profileButtonClick() {
      if (process.env.VUE_APP_MODE === 'production') return;
      this.$router.push('/profile');
    },
    logout() {
      //  this.$router.push('/login')
      console.log('ololol')
      this.logoutUser();
      // localStorage.isAuthAprokat = false;
      this.$router.push('/');
    },
    goToLogin() {
      this.updateShowAuthForm();
      // true;
      // this.$router.push('/login');
    },
    defaultClick() { },
    ...mapActions(['logoutUser']),
  },
  computed: {
    notProduction() {
      let flag = false;
      if (
        process.env.VUE_APP_MODE === 'development' ||
        process.env.NODE_ENV === 'development'
      )
        flag = true;
      return flag;
    },
    name() {
      if (!this.user.id) return null;
      if (!this.user.title) return 'Клиент';
      const userTitle = this.user.title.trim().split(' ');
      let user = userTitle[0];
      for (let i = 1; i < userTitle.length; i++) {
        user += ' ' + userTitle[i][0] + '.';
      }
      // const obj = userTitle &&
      //   userTitle.length > 1 && {
      //     2: `${userTitle[0]} ${userTitle[1][0]}.`,
      //     3: `${userTitle[0]} ${userTitle[1][0]}. ${userTitle[2][0]}.`,
      //   };
      // if (typeof obj[userTitle.length] !== 'undefined')
      //   user = obj[userTitle.length];
      return user || this.user.title;
    },
    ...mapGetters(['user']),
    invest() {
      // return true
      return this.user.roles.includes('2');
    },
    client() {
      // return true
      return this.user.roles.includes('1');
    },
  },
  // beforeUpdate() {
  //   // console.log('this is mounted');
  //   // console.log('user has roles', this.user.roles);
  //   // console.log('user has roles', this.user.roles.includes('2'));
  //   const userIsInvestor = this.user.roles.includes('2');
  //   // const userIsInvestor = true;
  //   if (userIsInvestor) {
  //     this.listItems = [
  //       {
  //         title: 'Бронирование',
  //         inNewTab: false,
  //         icon: 'mdi-car',
  //         route: '/cars',
  //         disabled: false,
  //       },
  //       {
  //         title: 'Финансы',
  //         icon: 'mdi-cash-check',
  //         route: '/operations',
  //         disabled: false,
  //         inNewTab: false,
  //         sub: [
  //           {
  //             title: 'Мои операции',
  //             icon: 'mdi-cash-check',
  //             inNewTab: false,
  //             route: '/operations/my',
  //           },
  //           {
  //             title: 'Доход авто',
  //             icon: 'mdi-car-arrow-right',
  //             inNewTab: false,
  //             route: '/operations/invest',
  //           },
  //         ],
  //       },
  //       {
  //         title: 'Выйти',
  //         inNewTab: false,
  //         icon: 'mdi-exit-to-app',
  //         route: '/logout',
  //         disabled: false,
  //       },
  //       //    {
  //       //        title: "Заявки",
  //       //        icon: "mdi-briefcase-variant",
  //       //        route: '/bookings',
  //       //        disabled: true
  //       //    },
  //       // {
  //       //   title: 'Обращения',
  //       //   icon: 'mdi-android-messages',
  //       //   inNewTab: false,
  //       //   route: '/messages',
  //       //   disabled: true,
  //       // },
  //       // {
  //       //   title: 'Календарь',
  //       //   icon: 'mdi-update',
  //       //   inNewTab: false,
  //       //   route: '/calendar',
  //       //   disabled: false,
  //       // },
  //     ];
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.subHeader {
  width: 200px;
}

.subItem {
  width: 220px;
}

.subTitle {
  width: 140px;
}
</style>
